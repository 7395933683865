import { Button, Checkbox, Divider, Form, Space } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
// import { FamilyPersons } from '../../../select/family-persons'
import { MinusCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { URL_NON_SAFETY_TYPES } from '../constants'
import { SmallInput } from '../../../input/small-input'
import { Title } from '../../../typography'
import { client } from '../../../../api/client'
import { URL_HOUSE_PERSONS } from '../../seventh-step/constants'
import {
  createOptionsFromArrayName,
  errorMessage,
} from '../../../../helpers/utils'
import { Select } from '../../../select'

const NonSafetyTypeReasons = ({ getData, getHouseHold }) => {
  const { data } = useGetQuestions(URL_NON_SAFETY_TYPES)
  const form = Form.useFormInstance()
  const [familyPersons, setFamilyPersons] = useState([])

  const fetchFamilyPersons = async () => {
    try {
      const response = await client.get(URL_HOUSE_PERSONS, {
        params: { householdId: +getHouseHold },
      })
      setFamilyPersons(response.data)
    } catch (error) {
      errorMessage(error)
    }
  }

  useEffect(() => {
    fetchFamilyPersons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHouseHold])

  useEffect(() => {
    if (getData) {
      const householdNonSafetyPeople =
        getData?.householdNonSafetyPeople?.map((safetyPeople) => ({
          ...safetyPeople,
          personNonSafetyTypes: safetyPeople?.personNonSafetyTypes?.map(
            (safetyType) => safetyType.id,
          ),
          ...safetyPeople?.personNonSafetyTypes?.reduce((acc, safetyType) => {
            acc[`comment_${safetyType.id}`] = safetyType?.comment
            return acc
          }, {}),
        })) || []

      const initialCheckedState = householdNonSafetyPeople?.reduce(
        (acc, item, index) => {
          acc[index] = item.personNonSafetyTypes?.reduce((typeAcc, typeId) => {
            typeAcc[typeId] = true
            return typeAcc
          }, {})
          return acc
        },
        {},
      )

      setTimeout(() => {
        form.setFieldsValue({
          householdNonSafetyPeople,
        })
        setIsChecked(initialCheckedState)
      }, 300)
    }
  }, [getData, form])

  const CheckboxWithComment = ({
    type,
    formItemName,
    onChange,
    isChecked,
    comment,
  }) => (
    <VerticalSpace>
      <Space>
        <Checkbox
          value={type.id}
          onChange={(e) => onChange(e.target.checked, type.id)} // Pass the ID of the checkbox to track it individually
          checked={isChecked} // Ensure the checkbox state reflects the form value
        >
          {type.name}
        </Checkbox>
        {type.hasComment && isChecked && (
          <Form.Item
            name={[...formItemName, `comment_${type.id}`]}
            initialValue={comment} // Set the initial comment value if present
            rules={[
              {
                required: false,
                message: 'Please add a comment for this item.',
              },
              {
                pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
              },
            ]}
            noStyle
          >
            <SmallInput size="small" />
          </Form.Item>
        )}
      </Space>
    </VerticalSpace>
  )

  const renderItems = (fields, add, remove) => (
    <>
      {fields.map(({ key, name, ...restField }) => (
        <VerticalSpace key={key}>
          <Space>
            <Form.Item
              {...restField}
              style={{ marginBottom: 0, width: 250 }}
              name={[name, 'personId']}
              rules={[{ required: true }]}
            >
              {/*<FamilyPersons name="householdNonSafetyPeople" />*/}
              <Select
                placeholder={'Նշել անդամի տվյալները'}
                options={createOptionsFromArrayName(familyPersons)}
              />
            </Form.Item>

            {fields.length > 1 && (
              <MinusCircleFilled
                style={{ fontSize: 24, color: '#456FAE' }}
                className="dynamic-delete-button"
                onClick={() => remove(name)}
              />
            )}
          </Space>

          <Form.Item
            label={<Title>Խնդրում եմ նկարագրել իրավիճակը</Title>}
            name={[name, 'personNonSafetyTypes']}
            rules={[{ required: true }]}
          >
            <Checkbox.Group>
              <VerticalSpace>
                {data.map((type) => (
                  <CheckboxWithComment
                    key={type.id}
                    type={type}
                    formItemName={[name]}
                    onChange={(checked, id) =>
                      handleCheckboxChange(checked, id, name)
                    }
                    isChecked={isChecked[name] && isChecked[name][type.id]}
                    comment={form.getFieldValue([name, `comment_${type.id}`])}
                  />
                ))}
              </VerticalSpace>
            </Checkbox.Group>
          </Form.Item>
          <Divider />
        </VerticalSpace>
      ))}

      <Form.Item>
        <Button type="primary" onClick={() => add()}>
          <PlusCircleOutlined />
          Ավելացնել ընտանիքի անդամ
        </Button>
      </Form.Item>
    </>
  )

  const [isChecked, setIsChecked] = useState({}) // Store checked state for each item

  const handleCheckboxChange = (checked, id, name) => {
    setIsChecked((prevState) => {
      const newState = { ...prevState }
      if (!newState[name]) newState[name] = {}
      newState[name][id] = checked
      return newState
    })
  }

  return (
    <Form.List name="householdNonSafetyPeople" initialValue={['']}>
      {(fields, { add, remove }) => renderItems(fields, add, remove)}
    </Form.List>
  )
}

export default NonSafetyTypeReasons
