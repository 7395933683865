import { useMutation } from '@tanstack/react-query'
import { client } from '../client'

export const usePostData = (url, options = {}) => {
  return useMutation({
    mutationFn: (data) => {
      const params = new URLSearchParams(data).toString()
      return client.get(`${url}?${params}`)
    },
    ...options,
  })
}
