import React from 'react'
import useGetQuestions, {
  URL_GET_FAMILY_MEMBERS,
} from '../../../../api/application/use-get-questions'
import { Collapse, Space, Spin } from 'antd'
import { Button } from '../../../button'
import { DeleteOutlined } from '@ant-design/icons'
import { COLORS } from '../../../../helpers/constants'
import { Select } from '../../../select'
import { createOptionsFromArray } from '../../../../helpers/utils'
import { FormItem } from '../../form-item'
import { SecondaryText } from '../../../typography'
import BeforeRelocationWorkForm from '../../../fill-application/eighth-step/before-relocation-work-form'
import CurrentWorkForm from '../../../fill-application/eighth-step/current-work-form'
import { Form } from '../../form'

const { Panel } = Collapse

const MemberOccupancy = ({ field, remove, disabled }) => {
  const form = Form.useFormInstance()
  const applicationId = form.getFieldValue('appId')
  const { data: members, isLoading } = useGetQuestions(
    `${URL_GET_FAMILY_MEMBERS}?householdId=${+applicationId}`,
  )

  const formattedMembers = members?.map((member) => {
    return {
      label: `${member.firstName} ${member.lastName} `,
      id: member.id,
    }
  })

  return (
    <Spin spinning={isLoading}>
      <Collapse defaultActiveKey={[field.name]} key={field.name}>
        <Panel
          key={field.name}
          header={'Ընտանիքի անդամի տվյալներ'}
          extra={
            <Space>
              {field.name !== 0 && !disabled && (
                <Button
                  text="Հեռացնել"
                  icon={<DeleteOutlined />}
                  style={{ color: COLORS.ALERT.RED }}
                  onClick={() => remove(field.name)}
                  type="text"
                >
                  Հեռացնել
                </Button>
              )}
            </Space>
          }
        >
          <FormItem
            name={[field.name, 'personId']}
            rules={[{ required: true }]}
            label={<SecondaryText>Ընտրել ընտանիքի անդամին</SecondaryText>}
          >
            <Select
              disabled={disabled}
              options={createOptionsFromArray(formattedMembers)}
            />
          </FormItem>
          <BeforeRelocationWorkForm field={field} disabled={disabled} />
          <CurrentWorkForm field={field} disabled={disabled} />
        </Panel>
      </Collapse>
    </Spin>
  )
}

export default MemberOccupancy
