import React from 'react'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import {
  reletionshipTypesWithAccomodationMembers,
  reletionshipTypesWithFamMembers,
} from '../../constatns'
import { Checkbox, Divider } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
import { SecondaryText } from '../../../typography'
import { Form } from '../../../form/form'
import { Input } from '../../../input'
import ViolenceForm from './violence-form'
import ViolenceFormFamily from './violence-form-family'

const RelationshipDescription = ({ getData, disabled }) => {
  const form = Form.useFormInstance()
  const homeRelationshipId = Form.useWatch('homeRelationshipId')
  const familyRelationshipId = Form.useWatch('familyRelationshipId')

  const isDisabledAll = homeRelationshipId?.includes(5)
  const isDisabledNotAnswer =
    homeRelationshipId?.length > 0 && homeRelationshipId.some((r) => r !== 5)

  const isDisabledAllSecond = familyRelationshipId?.includes(5)
  const isDisabledNotAnswerSecond =
    familyRelationshipId?.length > 0 &&
    familyRelationshipId.some((r) => r !== 5)

  return (
    <FormSectionCard title="Ինչպե՞ս կբնութագրեք ձեր փոխհարաբերությունները">
      <FormItem
        name="homeRelationshipId"
        rules={[
          {
            required: true,
          },
        ]}
        label={
          <SecondaryText>
            Ձեզ հետ ներկայիս վայրում (կացարանում) գտնվող անձանց հետ
          </SecondaryText>
        }
      >
        <Checkbox.Group>
          <VerticalSpace>
            {reletionshipTypesWithAccomodationMembers.map((row) => (
              <React.Fragment key={row.id}>
                <Checkbox
                  value={row.id}
                  key={row.id}
                  disabled={
                    disabled ||
                    (isDisabledAll && row.id !== 5) ||
                    (isDisabledNotAnswer && row.id === 5)
                  }
                  onChange={(e) => {
                    if (e.target.value === 6) {
                      form.resetFields(['homeRelationshipComment'])
                    }
                  }}
                >
                  {row.name}
                </Checkbox>

                {!!(homeRelationshipId?.includes(row.id) && row.hasComment) && (
                  <FormItem
                    name="homeRelationshipComment"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                        message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                      },
                    ]}
                  >
                    <Input disabled={disabled} placeholder="Նշել" />
                  </FormItem>
                )}
              </React.Fragment>
            ))}
          </VerticalSpace>
        </Checkbox.Group>
      </FormItem>

      {homeRelationshipId?.includes(4) && (
        <ViolenceForm
          title="Ինչպե՞ս է դա դրսևորվում"
          parentFormItem={'nonMemberViolences'}
          getData={getData}
          disabled={disabled}
        />
      )}

      <Divider />

      <FormItem
        name="familyRelationshipId"
        rules={[
          {
            required: true,
          },
        ]}
        label={<SecondaryText>Ընտանիքի անդամների հետ</SecondaryText>}
      >
        <Checkbox.Group>
          <VerticalSpace>
            {reletionshipTypesWithFamMembers.map((row) => (
              <Checkbox
                value={row.id}
                key={row.id}
                disabled={
                  disabled ||
                  (isDisabledAllSecond && row.id !== 5) ||
                  (isDisabledNotAnswerSecond && row.id === 5)
                }
                onChange={(e) => {
                  if (e.target.value === 6) {
                    form.resetFields(['familyRelationshipComment'])
                  }
                }}
              >
                {row.name}
              </Checkbox>
            ))}
          </VerticalSpace>
        </Checkbox.Group>
      </FormItem>

      {familyRelationshipId?.includes(6) && (
        <FormItem
          name="familyRelationshipComment"
          rules={[
            { required: true },
            {
              pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
              message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
            },
          ]}
        >
          <Input disabled={disabled} placeholder="Նշել" />
        </FormItem>
      )}

      {familyRelationshipId?.includes(4) && (
        <ViolenceFormFamily
          parentFormItem={'nonMemberViolencesFamily'}
          getData={getData}
          disabled={disabled}
        />
      )}
    </FormSectionCard>
  )
}

export default RelationshipDescription
