import { Select } from './index'
import { FormItem } from '../form/form-item'
import useGetQuestions, {
  URL_GET_FAMILY_MEMBER_RELATION,
  URL_GET_NON_MEMBER_RELATION,
} from '../../api/application/use-get-questions'
import { Spin } from 'antd'
import { useState } from 'react'
import { Input } from '../input'

export const FamilyRelationshipSelect = ({
  formItemProp,
  formItemCommentProp,
  isFamilyMember,
  disabled,
}) => {
  const { data: options, isLoading } = useGetQuestions(
    isFamilyMember
      ? URL_GET_FAMILY_MEMBER_RELATION
      : URL_GET_NON_MEMBER_RELATION,
  )
  const [hasComment, setHasComment] = useState(false)

  const handleSelect = (e) => {
    const option = options.filter((item) => item.id === e)
    if (option[0].hasComment) {
      setHasComment(true)
    } else {
      setHasComment(false)
    }
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem
        rules={[{ required: true }]}
        label="Ազգակցական կապ (տվյալ անձը հարցվողի ինչն է)"
        wrapperCol={{ span: 8 }}
        name={formItemProp}
      >
        <Select
          onChange={handleSelect}
          options={options.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          disabled={disabled}
        />
      </FormItem>
      {hasComment && (
        <FormItem
          rules={[{ required: true }]}
          wrapperCol={{ span: 8 }}
          name={formItemCommentProp}
        >
          <Input placeholder="Նշել" disabled={disabled} />
        </FormItem>
      )}
    </Spin>
  )
}
