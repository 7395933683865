import useGetQuestions from '../../../api/application/use-get-questions'
import { FormItem } from '../../form/form-item'
import { URL_SUPPORT_TYPE } from './constants'
import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { Form } from '../../form/form'
import { createName } from '../../../helpers/utils'
import { Input } from '../../input'

export const EntitySupportTypes = ({ parentName, disabled }) => {
  const form = Form.useFormInstance()
  const [supportType, setSupportType] = useState({})
  const { data: supportTypes } = useGetQuestions(URL_SUPPORT_TYPE)

  useEffect(() => {
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = createName(item.id)
          const isChecked = form.getFieldValue([
            ...parentName,
            'householdEntitySupportTypes',
            itemName,
            'isActive',
          ])
          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children)
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    if (!Object.keys(supportType || {}).length) {
      const initialCheckedIds = setInitialCheckedState(supportTypes)
      setSupportType(initialCheckedIds)
    }
  }, [form, parentName, supportType, supportTypes])

  // eslint-disable-next-line no-unused-vars
  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setSupportType((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      const currentSupportTypes =
        form.getFieldValue([...parentName, 'householdEntitySupportTypes']) || {}

      const updatedSupportTypes = {
        ...currentSupportTypes,
        [itemName]: checked
          ? { id: itemId, isChecked: checked, comment: '' }
          : undefined,
      }

      const sanitizedSupportTypes = Object.fromEntries(
        Object.entries(updatedSupportTypes).filter(
          ([value]) => value !== undefined,
        ),
      )

      form.setFields([
        {
          name: [...parentName, 'householdEntitySupportTypes'],
          value: sanitizedSupportTypes,
        },
        {
          name: [...parentName, itemName, 'id'],
          value: checked ? itemId : null,
        },
        {
          name: [...parentName, itemName, 'isChecked'],
          value: checked,
        },
        {
          name: [...parentName, itemName, 'comment'],
          value: checked ? '' : null,
        },
      ])
    },
    [form, parentName],
  )

  const handleChangeInput = (itemName, itemId, text) => {
    setSupportType((prevCheckedIds) => ({
      ...prevCheckedIds,
      [itemName]: true,
    }))

    const currentSupportTypes =
      form.getFieldValue([...parentName, 'householdEntitySupportTypes']) || {}

    const updatedSupportTypes = {
      ...currentSupportTypes,
      [itemName]: text
        ? { id: itemId, isChecked: true, comment: text }
        : undefined,
    }

    const sanitizedSupportTypes = Object.fromEntries(
      Object.entries(updatedSupportTypes).filter(
        ([value]) => value !== undefined,
      ),
    )

    form.setFields([
      {
        name: [...parentName, 'householdEntitySupportTypes'],
        value: sanitizedSupportTypes,
      },
      {
        name: [...parentName, itemName, 'id'],
        value: text ? itemId : null,
      },
      {
        name: [...parentName, itemName, 'isChecked'],
        value: true,
      },
      {
        name: [...parentName, itemName, 'comment'],
        value: text ? text : null,
      },
    ])
  }

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            valuePropName="checked"
            initialValue={state[itemName] || false}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items, state)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={state[itemName]}
              // checked={state[itemName] || false}
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  setState,
                  parentName,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label="Այլ (նշել)"
              rules={[
                { required: true },
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              style={{ marginLeft }}
            >
              <Input
                disabled={disabled}
                onChange={(e) =>
                  handleChangeInput(itemName, item.id, e.target.value)
                }
              />
            </FormItem>
          )}
          <FormItem name={[...parentName, itemName, 'id']} hidden />
        </React.Fragment>
      )
    })
  }

  return (
    <FormItem name={[...parentName, 'householdEntitySupportTypes']}>
      {renderItems(supportTypes, 1, supportType, setSupportType, [
        ...parentName,
        'householdEntitySupportTypes',
      ])}
    </FormItem>
  )
}
