import React, { useEffect, useState } from 'react'
import { Upload, Button, message, Modal } from 'antd'
import {
  UploadOutlined,
  FileImageOutlined,
  PaperClipOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { COLORS } from '../../../helpers/constants'
import { FormItem } from '../../form/form-item'
import { Form } from '../../form/form'
import styled from 'styled-components'

const UploadStyled = styled(Upload)`
  &.ant-upload-wrapper {
    display: flex;
    flex-direction: column;
    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;

      .ant-upload-list-item {
        cursor: pointer;
        &:hover {
          background-color: transparent;
        }
      }

      .ant-upload-list-item-name {
        color: ${COLORS.PRIMARY.BLUE};
        border-bottom: 1px solid ${COLORS.PRIMARY.BLUE};
        white-space: nowrap;
        overflow: hidden;
        max-width: 100px;
        text-overflow: ellipsis;
      }
    }

    @media only screen and (max-width: 768px) {
      display: block;
      margin: 10px;
      .ant-upload-list {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
`

const UploadApplicationDocument = ({ householdId, getData, disabled }) => {
  const [base64File, setBase64File] = useState(
    getData ? getData?.document : null,
  )
  const [previewVisible, setPreviewVisible] = useState(false)
  const [isPdf, setIsPdf] = useState()
  const fileTypes = ['.pdf', '.png', '.jpeg', '.jpg']
  const allowedTypes = fileTypes.join(',')
  const maxSize = 5 // in MB

  const form = Form.useFormInstance()

  useEffect(() => {
    if (householdId) {
      setTimeout(() => {
        const base64String = getData?.document
        if (base64String) {
          const mimeType = base64String.match(/^data:(.*?);base64,/)?.[1]
          if (mimeType === 'application/pdf') {
            setIsPdf(true)
          }
          form.setFieldValue('fileBase64', getData?.document)
        }
      }, 300)
    }
  }, [householdId, getData, form])

  const defaultProps = {
    beforeUpload: (file) => {
      const isFileTypeAllowed = fileTypes.some((type) =>
        file.name.toLowerCase().endsWith(type),
      )
      const isFileSizeAllowed = file.size / 1024 / 1024 < maxSize
      if (!isFileTypeAllowed) {
        message.error(`Միայն ${allowedTypes} տեսակի ֆայլերն են թույլատրվում.`)
      }
      if (!isFileSizeAllowed) {
        message.error(`Ֆայլի չափսը պետք է լինի ${maxSize}MB ից փոքր`)
      }
      return (isFileTypeAllowed && isFileSizeAllowed) || Upload.LIST_IGNORE
    },
    actionIcon: <PaperClipOutlined />,
    accept: allowedTypes,
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
      removeIcon: <CloseOutlined style={{ color: COLORS.ALERT.RED }} />,
    },
  }

  const customRequest = ({ file, onError, onSuccess }) => {
    try {
      const reader = new FileReader()
      reader.onload = (e) => {
        setBase64File(e.target.result)
        onSuccess()
        form.setFieldValue('fileBase64', e.target.result)
      }
      reader.readAsDataURL(file)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error reading file:', error)
      onError(error)
    }
  }

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      if (info.file.type === 'application/pdf') {
        setIsPdf(true)
      } else {
        setIsPdf(false)
      }
      message.success(`${info.file.name} Ֆայլը հաջողությամբ ներբեռնվեց`)
    } else if (info.file.status === 'error') {
      message.error(`Ֆայլի ներբեռնումը չհաջողվեց`)
    }
  }

  const handlePreview = () => {
    setPreviewVisible(true)
  }

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  return (
    <div style={{ display: 'flex', alignItems: 'start' }}>
      <FormItem
        label=""
        valuePropName="fileList"
        name={'fileList'}
        getValueFromEvent={normFile}
        rules={[{ required: !base64File, message: 'Տեղեկանք կցված չէ' }]}
      >
        {!disabled && (
          <UploadStyled
            customRequest={customRequest}
            showUploadList={true}
            onChange={handleChange}
            onRemove={() => setBase64File(null)}
            maxCount={1}
            {...defaultProps}
          >
            <Button icon={<UploadOutlined />}>Կցել տեղեկանք</Button>
          </UploadStyled>
        )}
      </FormItem>
      {base64File && (
        <Button
          icon={<FileImageOutlined />}
          style={{ marginLeft: '8px' }}
          onClick={handlePreview}
          disabled={false}
        >
          Դիտել տեղեկանքը
        </Button>
      )}
      <Modal
        open={previewVisible}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        {base64File &&
          (isPdf ? (
            <iframe
              src={base64File}
              style={{ width: '100%', height: '80vh' }}
            />
          ) : (
            <img
              alt="img"
              style={{ width: '100%', height: 'auto' }}
              src={base64File}
            />
          ))}
      </Modal>
    </div>
  )
}

export default UploadApplicationDocument
