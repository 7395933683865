import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { FormItem } from '../../form-item'
import { Checkbox } from '../../../checkbox'
import { nonMedicalSupportReasons } from '../../../fill-application/constatns'
import { SecondaryText } from '../../../typography'

const createName = (id) => `item${id}`

export const PersonNonMedicalSupport = ({ field, getData, disabled }) => {
  const form = Form.useFormInstance()
  const [nonMedicalSupportIds, setNonMedicalSupportIds] = useState({})

  useEffect(() => {
    if (getData) {
      const familyMembers = getData?.familyData?.familyMembers || []

      const initialCheckedState = familyMembers.reduce((acc, item) => {
        item?.personNonMedicalSupportReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            id: supportType.id,
            comment: supportType?.comment || '',
          }
        })
        return acc
      }, {})

      setTimeout(() => {
        form.setFieldsValue({
          familyData: {
            familyMembers: {
              [field.name]: {
                personNonMedicalSupportReasons: initialCheckedState,
              },
            },
          },
        })
        setNonMedicalSupportIds(initialCheckedState || {})
      }, 300)
    }
  }, [getData, field.name, form])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setNonMedicalSupportIds((prevCheckedIds) => {
        const updatedState = { ...prevCheckedIds }

        if (checked) {
          updatedState[itemName] = { id: itemId }
        } else {
          delete updatedState[itemName]
        }

        form.setFieldsValue({
          familyData: {
            familyMembers: {
              [field.name]: {
                personNonMedicalSupportReasons: Object.values(updatedState),
              },
            },
          },
        })

        return updatedState
      })

      // form.validateFields([
      //   'familyData',
      //   'familyMembers',
      //   field.name,
      //   'personNonMedicalSupportReasons',
      // ])
    },
    [form, field.name],
  )

  const handleInputChange = useCallback(
    (itemName, itemId, value) => {
      setNonMedicalSupportIds((prevState) => {
        const updatedState = { ...prevState }

        /* eslint-disable no-console */
        if (updatedState[itemName] && updatedState[itemName].id === itemId) {
          updatedState[itemName] = {
            ...updatedState[itemName],
            comment: value,
          }
        }

        form.setFields([
          {
            name: [
              'familyData',
              'familyMembers',
              field.name,
              'personNonMedicalSupportReasons',
            ],
            value: Object.values(updatedState),
          },
        ])
        return updatedState
      })
    },
    [form, field.name],
  )
  const validateCheckboxGroup = () => {
    const isChecked = Object.values(nonMedicalSupportIds).some((value) => value)
    return isChecked
      ? Promise.resolve()
      : Promise.reject(new Error('Անհրաժեշտ է նշել նվազագույնը մեկ արժեք'))
  }

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      const isChecked = Boolean(nonMedicalSupportIds[itemName])

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'personNonMedicalSupportReasons', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: validateCheckboxGroup,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={isChecked}
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.label}
            </Checkbox>
          </FormItem>
          {item.hasComment && isChecked && (
            <FormItem
              name={[
                field.name,
                'personNonMedicalSupportReasons',
                itemName,
                'comment',
              ]}
              rules={[
                // { required: true },
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              style={{ marginLeft }}
            >
              <Input
                defaultValue={nonMedicalSupportIds[itemName]?.comment || ''}
                onChange={(e) =>
                  handleInputChange(itemName, item.id, e.target.value)
                }
                disabled={disabled}
              />
            </FormItem>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <SecondaryText>
        Ո՞րն է բուժում չստանալու (կամ բուժումը չշարունակելու) պատճառը
      </SecondaryText>
      {renderItems(nonMedicalSupportReasons)}
    </>
  )
}
