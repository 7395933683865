import React from 'react'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { FormItem } from '../form-item'
import { Title } from '../../typography'
import { TextArea } from '../../input'

const Notes = ({ formItemName, label, required = false, disabled = false }) => {
  return (
    <FormSectionFullCard>
      <FormItem
        label={<Title>{label || 'Լրացուցիչ նշումներ'}</Title>}
        name={formItemName}
        rules={[
          { required },
          {
            pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
            message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
          },
        ]}
        disabled={disabled}
      >
        <TextArea maxLength={500} />
      </FormItem>
    </FormSectionFullCard>
  )
}

export default Notes
