import React, { useState } from 'react'
import { Col, Collapse, Form, Row } from 'antd'
import { FormItem } from '../../form/form-item'
import { Input } from '../../input'
import { Button } from '../../button'
import { RegionsSelect } from '../../select/regions-select'
import { CommunitySelect } from '../../select/community-select'
import { AddressSelect } from '../../select/address-select'
import { usePostData } from '../../../api/application/usePostData'
import { URL_GET_SOCIAL_EMPLOYEES_DATA } from '../../../api/application/use-get-questions'

const { Panel } = Collapse

const FilterEmployee = ({ setResultFilter }) => {
  const form = Form.useFormInstance()
  const [stateId, setStateId] = useState(null)
  const [communityId, setCommunityId] = useState(null)

  const onStateChange = (val) => {
    form.resetFields(['CommunityId'])
    form.resetFields(['AddressId'])
    setStateId(val)
  }

  const onCummunityChange = (val) => {
    form.resetFields(['AddressId'])
    setCommunityId(val)
  }

  const { mutate } = usePostData(URL_GET_SOCIAL_EMPLOYEES_DATA, {
    onSuccess: (response) => {
      setResultFilter(() => ({
        data: response?.data.data,
        totalCount: response?.data.totalCount,
      }))
    },
  })

  const onFilterEmployee = () => {
    const values = form.getFieldsValue(true)
    const valuesArray = Object.entries(values)

    const arrayFilter = []
    valuesArray.map((value) => {
      if (value[1] != undefined) {
        arrayFilter.push(value)
      }
    })

    const valuesObject = Object.fromEntries(arrayFilter)
    mutate(valuesObject)
  }

  const onClearForm = () => {
    mutate([])
  }

  return (
    <Collapse style={{ marginBottom: 20 }}>
      <Panel key={1} header={'Ընդլայնված որոնում'}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <FormItem name={'SerialNumber'} label="Դիմումի համար">
              <Input disabled={false} />
            </FormItem>
          </Col>
          {/*<Col span={8}>*/}
          {/*  <FormItem name={'SerialNumber'} label="Անձը հաստատող փաստաթուղթ">*/}
          {/*    <Input disabled={false} />*/}
          {/*  </FormItem>*/}
          {/*</Col>*/}
          <Col span={12}>
            <FormItem name={'Ssn'} label="ՀԾՀ">
              <Input disabled={false} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={'FirstName'} label="Անուն">
              <Input disabled={false} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={'LastName'} label="Ազգանուն">
              <Input disabled={false} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={'PatronymicName'} label="Հայրանուն">
              <Input disabled={false} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={'StateId'} label="Մարզ">
              <RegionsSelect onChange={onStateChange} disabled={false} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={'CommunityId'} label="Համայնք">
              <CommunitySelect
                stateId={stateId}
                onChange={onCummunityChange}
                disabled={false}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={'AddressId'} label="Բնակավայր">
              <AddressSelect communityId={communityId} disabled={false} />
            </FormItem>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ marginRight: 20 }}
              disabled={false}
              shape="round"
              type="primary"
              size="large"
              onClick={onFilterEmployee}
            >
              Որոնել
            </Button>
            <Button
              disabled={false}
              shape="round"
              size="large"
              onClick={onClearForm}
            >
              Մաքրել
            </Button>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default FilterEmployee
