import React, { useCallback } from 'react'
import { FormItem } from '../../../form/form-item'
import { Form } from 'antd'
import { Input } from '../../../input'

const SnInput = ({ nameItem, row, style }) => {
  const form = Form.useFormInstance()

  const onInputChangeSupport = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('personHealthNeeds')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('personHealthNeeds')]
      newData[index].comment = value
      form.setFieldsValue({ personHealthNeeds: newData })
    },
    [form],
  )

  return (
    <FormItem
      rules={[
        { required: true },
        {
          pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
          message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
        },
      ]}
      name={nameItem}
      style={style}
    >
      <Input
        placeholder={'Նշել'}
        onChange={(e) => onInputChangeSupport(e.target.value, row.id)}
      />
    </FormItem>
  )
}

export default SnInput
