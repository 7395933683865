import React, { useEffect, useState } from 'react'
import { Form } from '../../form'
import { FormItem } from '../../form-item'
import { SecondaryText, Title } from '../../../typography'
import { Input } from '../../../input'
import { medicalSupportFrequency } from '../../../fill-application/constatns'
import { Radio } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
import { FormSectionFullCard } from '../../../card/form-section-full-card'
import { MedicalSupport } from './medical-support'
import { PersonNonMedicalSupport } from './person-non-medical-support'

const PhysicalSupportForm = ({ parentProp, field, getData, disabled }) => {
  const form = Form.useFormInstance()
  const [supportFrequency, setSupportFrequency] = useState({})
  const [supportFrequencyNumb, setSupportFrequencyNumb] = useState({})
  const [emptyGetData, setEmptyGetData] = useState(false)

  useEffect(() => {
    if (getData) {
      const initialmedicalFrequency = getData?.familyData?.familyMembers.find(
        (member) => member.medicalFrequencyTypeId !== null,
      )?.medicalFrequencyTypeId
      setSupportFrequencyNumb(initialmedicalFrequency)
    }
  }, [getData])

  const physicalInjury = Form.useWatch(
    [...parentProp, field.name, 'physicalInjury'],
    {
      preserve: true,
    },
  )

  const hadAppliedToDoctor = Form.useWatch(
    [...parentProp, field.name, 'medicalSupport'],
    {
      preserve: true,
    },
  )
  const continueMedicalSupport = Form.useWatch(
    [...parentProp, field.name, 'continueMedicalSupport'],
    {
      preserve: true,
    },
  )

  const handleRadioChange = (option, e, setState) => {
    setSupportFrequencyNumb(e.target.value)
    setState({
      id: e.target.value,
      ...(option.hasComment ? { comment: '' } : null),
    })
  }

  return (
    <FormSectionFullCard>
      <FormItem
        name={[field.name, 'physicalInjury']}
        label={
          <Title>
            Արդյո՞ք բռնի տեղահանման հետևանքով/ ընթացքում ստացել եք ֆիզիկական
            վիրավորում/վնասվածք
          </Title>
        }
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={() => {
            form.resetFields([
              ['familyData', 'familyMembers', field.name, 'medicalSupport'],
              [
                'familyData',
                'familyMembers',
                field.name,
                'continueMedicalSupport',
              ],
            ])
            setEmptyGetData(true)
          }}
          disabled={disabled}
        >
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {physicalInjury && (
        <FormItem
          name={[field.name, 'medicalSupport']}
          rules={[{ required: true }]}
          label={<Title>Արդյո՞ք ստացել եք բժշկական աջակցություն</Title>}
        >
          <Radio.Group
            onChange={() => {
              setEmptyGetData(true)
              form.resetFields([
                [
                  'familyData',
                  'familyMembers',
                  field.name,
                  'continueMedicalSupport',
                ],
                [
                  'familyData',
                  'familyMembers',
                  field.name,
                  'medicalFrequencyTypeId',
                ],
              ])
            }}
            disabled={disabled}
          >
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
      )}

      {hadAppliedToDoctor && physicalInjury && (
        <>
          <MedicalSupport
            name={[field.name, 'personMedicalSupportTypes']}
            field={field}
            getData={emptyGetData ? null : getData}
            disabled={disabled}
          />
          <FormItem
            name={[field.name, 'continueMedicalSupport']}
            label={
              <SecondaryText>
                Արդյո՞ք նա շարունակում է օգտվել այդ բժշկական օգնությունից և
                սպասարկումից
              </SecondaryText>
            }
            rules={[{ required: true }]}
          >
            <Radio.Group
              onChange={() => {
                setSupportFrequencyNumb({})
                form.resetFields([
                  [
                    'familyData',
                    'familyMembers',
                    field.name,
                    'medicalFrequencyTypeId',
                  ],
                  [
                    'familyData',
                    'familyMembers',
                    field.name,
                    'medicalFrequencyTypeComment',
                  ],
                ])
              }}
              disabled={disabled}
            >
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </FormItem>
          {continueMedicalSupport && (
            <>
              <FormItem
                name={[field.name, 'medicalFrequencyTypeId']}
                rules={[{ required: true }]}
              >
                <Radio.Group disabled={disabled}>
                  <VerticalSpace>
                    {medicalSupportFrequency.map((option) => {
                      return (
                        <Radio
                          value={option.id}
                          key={option.id}
                          onChange={(e) => {
                            handleRadioChange(option, e, setSupportFrequency)
                            form.resetFields([
                              [
                                'familyData',
                                'familyMembers',
                                field.name,
                                'medicalFrequencyTypeComment',
                              ],
                            ])
                          }}
                          disabled={disabled}
                        >
                          {option.label}
                        </Radio>
                      )
                    })}
                  </VerticalSpace>
                </Radio.Group>
              </FormItem>
              {(Object.prototype.hasOwnProperty.call(
                supportFrequency,
                'comment',
              ) ||
                supportFrequencyNumb === 5) && (
                <FormItem
                  name={[field.name, 'medicalFrequencyTypeComment']}
                  rules={[
                    { required: true },
                    {
                      pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                      message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                    },
                  ]}
                >
                  <Input disabled={disabled} />
                </FormItem>
              )}
            </>
          )}
        </>
      )}
      {hadAppliedToDoctor === false && physicalInjury && (
        <PersonNonMedicalSupport
          field={field}
          getData={emptyGetData ? null : getData}
          disabled={disabled}
        />
      )}
    </FormSectionFullCard>
  )
}

export default PhysicalSupportForm
