import { VerticalSpace } from '../../space/vertical-space'
import { HowLongFoodSupplies } from './how-long-food-supplies'
import { DuringPastSevenDays } from './during-past-seven-days'
import { WhatThreeMainSources } from './what-three-main-sources'
import { NotEnoughMoneyHousehold } from './not-enough-money-household'
import React, { useEffect } from 'react'
import { Form } from '../../form/form'

export const FifthStep = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        foodSuppliesPeriodId: getData?.foodSuppliesPeriodId,
      })
    } else {
      form.resetFields([
        'foodSuppliesPeriodId',
        'foodStrategies',
        'foodSource',
        'foodActions',
      ])
    }
  }, [getData, form])
  return (
    <VerticalSpace>
      <HowLongFoodSupplies disabled={disabled} />

      <DuringPastSevenDays getData={getData} disabled={disabled} />

      <WhatThreeMainSources getData={getData} disabled={disabled} />

      <NotEnoughMoneyHousehold getData={getData} disabled={disabled} />
    </VerticalSpace>
  )
}
