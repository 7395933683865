import React, { useEffect, useState } from 'react'
import { Form, Row, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_GET_ACTION } from './constant'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Input } from '../../input'

const createName = (id) => `Action${id}`

export const DailyHouseholdNeeds = ({ getData, disabled }) => {
  const form = Form.useFormInstance()
  const { data: questions, isLoading } = useGetQuestions(URL_GET_ACTION)
  const [householdHaveTypeValue, sethouseholdHaveTypeValue] = useState([])
  const appliancesChecklist = Form.useWatch('householdHaveToActions', {
    preserve: true,
  })

  form.setFieldsValue({ householdHaveTypeValue: householdHaveTypeValue })

  useEffect(() => {
    if (getData) {
      const householdHaveToActions = getData.householdHaveToActions || []

      const formValues = { householdHaveToActions: {} }
      const updatedCheckedIds = {}

      householdHaveToActions.forEach((item) => {
        const itemName = createName(item.id)
        formValues.householdHaveToActions[itemName] = {
          id: item.id,
          comment: item.comment || '',
        }
        updatedCheckedIds[itemName] = true
      })

      form.setFieldsValue(formValues)
      sethouseholdHaveTypeValue(formValues)
    }
  }, [getData, form])

  const handleFirstCheckboxChange = (id, checked, commentValue) => {
    const itemName = createName(id)
    sethouseholdHaveTypeValue((prevState) => ({
      ...prevState,
      [itemName]: {
        id: checked ? id : null,
        comment: checked ? commentValue || '' : '',
      },
    }))

    // Set the form value as well
    form.setFieldsValue({
      householdHaveToActions: {
        [itemName]: {
          id: checked ? id : null,
          comment: commentValue || '',
        },
      },
    })

    if (!checked) {
      form.resetFields([['householdHaveToActions', itemName, 'comment']])
    }
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem hidden name={'householdHaveTypeValue'}></FormItem>
      <FormSectionCard
        title={
          'Արդյո՞ք Ձեր տնային տնտեսության առօրյա կարիքները հոգալու համար վերջին 30 օրվա ընթացքում ստիպված եք եղել կատարել նշված գործողություններից որևէ մեկը կամ մի քանիսը'
        }
        showRequired={true}
      >
        <Row>
          <FormItem
            name={'householdHaveToActions'}
            rules={[{ required: true }]}
          >
            {questions.map((item) => {
              return (
                <FormItem
                  name={['householdHaveToActions', item.label]}
                  key={item.id}
                >
                  <React.Fragment>
                    <FormItem
                      name={['householdHaveToActions', item.label, 'id']}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={disabled}
                        onChange={(e) =>
                          handleFirstCheckboxChange(item.id, e.target.checked)
                        }
                        value={item.id}
                      >
                        {item.name}
                      </Checkbox>
                    </FormItem>
                    {item.hasComment &&
                    appliancesChecklist?.[item.label]?.id ? (
                      <FormItem
                        name={[
                          'householdHaveToActions',
                          createName(item.id),
                          'comment',
                        ]}
                        style={{ margin: '10px 0 0 20px' }}
                        label={item.name}
                        rules={[
                          { required: true },
                          {
                            pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                            message:
                              'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                          },
                        ]}
                      >
                        <Input
                          disabled={disabled}
                          onChange={(e) =>
                            handleFirstCheckboxChange(
                              item.id,
                              true,
                              e.target.value,
                            )
                          }
                        />
                      </FormItem>
                    ) : (
                      <FormItem hidden />
                    )}
                  </React.Fragment>
                </FormItem>
              )
            })}
          </FormItem>
        </Row>
      </FormSectionCard>
    </Spin>
  )
}
