import { Col, Row, Spin } from 'antd'
import { Button } from '../../button'
import { Form } from '../form'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../form-item'
import { Input } from '../../input'
import { UserOutlined } from '@ant-design/icons'
import { SigninCard } from '../../card/signin-card'
import { useState } from 'react'
import ForgotPasswordModal from '../../modal/forgot-pass-modal'
import { URL_EMAIL_AUTHENTICATE } from '../../../api/auth/constants'
import { client } from '../../../api/client'
import { errorMessage } from '../../../helpers/utils'

export const ForgotPassword = () => {
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [username, setUsername] = useState(null)
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const response = await client.post(URL_EMAIL_AUTHENTICATE, values)
      if (response.status === 200) {
        setVisible(true)
        setUsername(values?.username)
      }
    } catch (error) {
      errorMessage(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
      <Spin spinning={loading}>
        <Col>
          <SigninCard>
            <Form
              name="forgotpassword"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
            >
              <VerticalSpace size="large">
                <FormItem
                  name="username"
                  label="Մուտքանուն"
                  rules={[{ required: true }, { type: 'email' }]}
                >
                  <Input placeholder="մուտքանուն" prefix={<UserOutlined />} />
                </FormItem>
                <VerticalSpace size="middle">
                  <Button htmlType="submit" block type="primary" size="large">
                    Շարունակել
                  </Button>
                </VerticalSpace>
              </VerticalSpace>
            </Form>
          </SigninCard>
        </Col>
      </Spin>

      <ForgotPasswordModal
        visible={visible}
        onClose={() => setVisible(false)}
        username={username}
      />
    </Row>
  )
}
