import { Form, Radio } from 'antd'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import { VerticalSpace } from '../../../space/vertical-space'
import NonSafetyTypeReasons from './non-safety-type-reasons'
import { useEffect } from 'react'

const NonSafetyType = ({ getData, getHouseHold }) => {
  const id = Form.useWatch(['familyMemberSafetyId'])
  const form = Form.useFormInstance()

  useEffect(() => {
    form.resetFields(['householdNonSafetyPeople'])
  }, [id, form])

  return (
    <FormSectionCard title="Արդյո՞ք ձեր ընտանիքում կա անձ, ում անվտանգության հետ կապված դուք անհանգստություն ունեք">
      <FormItem name="familyMemberSafetyId" rules={[{ required: true }]}>
        <Radio.Group>
          <VerticalSpace>
            <Radio value={1}>Այո</Radio>
            <Radio value={2}>Ոչ</Radio>
            <Radio value={3}>Դժվարանում է պատասխանել</Radio>
          </VerticalSpace>
        </Radio.Group>
      </FormItem>

      {id === 1 ? (
        <NonSafetyTypeReasons getData={getData} getHouseHold={getHouseHold} />
      ) : null}
    </FormSectionCard>
  )
}

export default NonSafetyType
