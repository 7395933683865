import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'

import EducationQuestions from './education-questions'
import { Form } from 'antd'

export const SeventhStep = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      if (getData.householdPersonEducationOutputModel) {
        const formattedValues = {}
        for (let i = 1; i <= 6; i++) {
          const entry = getData.householdPersonEducationOutputModel.find(
            (item) => item.id === i,
          )
          formattedValues[`radioButton${i}`] = {
            id: entry ? entry.id : false,
            memberEducation: entry
              ? entry.personEducationTypes.map((education) => ({
                  ...education,
                  personId: education.personId,
                  personEducationNotAttendReasons:
                    education.personEducationNotAttendReasons.reduce(
                      (acc, reason) => {
                        acc[`item${reason.id}`] = {
                          ...reason,
                          checked: true,
                        }
                        return acc
                      },
                      {},
                    ),
                  householdEducationNeeds:
                    education.householdEducationNeeds.reduce((acc, needs) => {
                      acc[`item${needs.id}`] = {
                        ...needs,
                        checked: true,
                      }
                      return acc
                    }, {}),
                }))
              : [],
          }
        }

        form.setFieldsValue({
          householdPersonEducationTypes: formattedValues,
        })
      }
    } else {
      form.resetFields(['householdPersonEducationTypes'])
    }
  }, [form, getData])
  return (
    <VerticalSpace>
      <EducationQuestions disabled={disabled} />
    </VerticalSpace>
  )
}
