import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import ViolenceCase from './violence-case'
import NonSafetyType from './non-safety-type/non-safety-type'
import { Form } from 'antd'
import RelationshipDescription from './relationship-description/relationship-description'
import ChildrenInteracting from './children-interacting/children-interacting'
import BadAttitude from './bad-attitude/bad-attitude'

const TwelfthStepForm = ({ getData, getHouseHold, disabled }) => {
  const form = Form.useFormInstance()

  function getFilteredValues(
    data,
    isActiveCondition = true,
    personIdCondition = null,
  ) {
    return (
      data
        ?.filter(
          (item) =>
            item.isActive === isActiveCondition &&
            item.personId === personIdCondition,
        )
        .map((item) => item.id) || []
    )
  }

  // function getFilteredValueComments(
  //   data,
  //   isActiveCondition = true,
  //   personIdCondition = null,
  // ) {
  //   return (
  //     data
  //       ?.filter(
  //         (item) =>
  //           item.isActive === isActiveCondition &&
  //           item.comment &&
  //           item.personId === personIdCondition,
  //       )
  //       .reduce((acc, item) => acc + item.comment, '') || ''
  //   )
  // }

  useEffect(() => {
    if (getData) {
      // const updatedCheckedIds = {}
      // eslint-disable-next-line no-unused-vars
      // const householdChildrenInteractingTypesList =
      //   getData?.householdBadAttitudePersonTypes?.reduce(
      //     (acc, curr) => {
      //       // Use `item${curr.id}` as the inner key for `householdBadAttitudePersonTypes`
      //       acc[`householdBadAttitudePersonTypes`][`item${curr.id}`] =
      //         curr.isActive

      //       // Update the checkedIds
      //       updatedCheckedIds[`item${curr.id}`] = curr.isActive

      //       return acc
      //     },
      //     { householdBadAttitudePersonTypes: {} },
      //   )

      // eslint-disable-next-line no-unused-vars
      // const createPlainList = (obj) => {
      //   let res = Object.values(obj)
      //     .filter((item) => item?.id)
      //     .map((x) => {
      //       return {
      //         ...x,
      //         householdBadAttitudeDemonstrationTypes: Object.values(
      //           x.householdBadAttitudeDemonstrationTypes,
      //         ).filter((item) => item?.id),
      //       }
      //     })
      //   return res
      // }

      // const householdChildrenInteractingTypesList =
      //   getData?.householdChildrenInteractingTypes?.reduce((acc, curr) => {
      //     acc[`item${curr.id}`] = curr.isActive
      //     return acc
      //   }, {})

      form.setFieldsValue({
        ...getData,
        homeRelationshipId: getFilteredValues(getData?.homeRelationshipId),
        // homeRelationshipComment: getFilteredValueComments(
        //   getData?.homeRelationshipId,
        // ),
        familyRelationshipId: getFilteredValues(getData?.familyRelationshipId),
        // familyRelationshipComment: getFilteredValueComments(
        //   getData?.familyRelationshipId,
        // ),
        householdBadAttitudePersonTypes:
          getData?.householdBadAttitudePersonTypes?.reduce(
            (acc, personType) => {
              acc[`item${personType.id}`] = {
                ...personType,
                householdBadAttitudeTypes:
                  personType.householdBadAttitudeTypes?.reduce(
                    (typeAcc, type) => {
                      typeAcc[`item${type.id}`] = {
                        ...type,
                        householdBadAttitudeDemonstrationTypes:
                          type.householdBadAttitudeDemonstrationTypes?.reduce(
                            (demoAcc, demoType) => {
                              demoAcc[`item${demoType.id}`] = { ...demoType }
                              return demoAcc
                            },
                            {},
                          ),
                      }
                      return typeAcc
                    },
                    {},
                  ),
              }
              return acc
            },
            {},
          ) || {},
        familyRelationshipComment: getData?.familyRelationshipComment,
        homeRelationshipComment: getData?.homeRelationshipComment,
        // application-fill-form_householdNonSafetyPeople_0_personNonSafetyTypes
        // householdNonSafetyPeople:
        //   getData?.householdNonSafetyPeople?.map((person) => ({
        //     personId: person.personId,
        //     personNonSafetyTypes: person.personNonSafetyTypes.map((type) => ({
        //       ...type,
        //       comment: type.comment || '', // Ensure there's always a comment field
        //     })),
        //   })) || [],
      })
    } else {
      form.resetFields([
        'homeRelationshipId',
        'familyRelationshipId',
        'householdBadAttitudePersonTypes',
        'familyRelationshipComment',
        'homeRelationshipComment',
        'homeRelationshipId',
        'familyMemberSafetyId',
        'householdChildrenInteractingTypes',
        'violenceInCommunity',
        'violenceInAccommodation',
        'attitudeId',
        'nonMemberViolences',
        'nonMemberViolencesFamily',
        'householdViolencePersonTypes',
        'householdViolencePersonTypes',
      ])
    }
  }, [form, getData])

  return (
    <VerticalSpace>
      <RelationshipDescription getData={getData} disabled={disabled} />

      <ViolenceCase />

      <NonSafetyType getData={getData} getHouseHold={getHouseHold} />

      <BadAttitude />

      <ChildrenInteracting getData={getData} />
    </VerticalSpace>
  )
}

export default TwelfthStepForm
