import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Form, Spin, Row, Col } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { URL_GET_PHARMACY_TYPE } from './constants'
import { Input } from '../../input'
import useGetQuestions from '../../../api/application/use-get-questions'

const PharmacyType = ({ getData, disabled }) => {
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)
  const [disable, setDisable] = useState(false)
  const [disableLast, setDisableLast] = useState(false)
  const [parmacySourceValue, setParmacySourceValue] = useState([])
  const [pharmacyTypeComment, setPharmacyTypeComment] = useState('')
  const [isItem6Present, setIsItem6Present] = useState(false)

  const selectPharmacyType = Form.useWatch('changePharmacyType', {
    preserve: true,
  })

  useEffect(() => {
    const isItem6 = getData?.householdPharmacyTypes?.some(
      (item) => item.id === 6,
    )
    setIsItem6Present(isItem6)
  }, [getData])

  const transformedData = useMemo(() => {
    if (!getData) return {}

    return {
      changePharmacyType: getData?.householdPharmacyTypes?.reduce(
        (acc, needs) => {
          acc[`PharmacyType${needs.id}`] = {
            id: needs.id,
            comment: needs.comment || null,
          }
          setPharmacyTypeComment(needs.comment)
          return acc
        },
        {},
      ),
      pharmacyTypeInput5: getData?.householdPharmacyTypes?.filter(
        (needs) => needs.id === 5,
      )[0]?.comment,
    }
  }, [getData])
  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        ...transformedData,
      })
    }
  }, [getData, transformedData, form])

  // eslint-disable-next-line no-unused-vars
  const pharmacyTypeInput = Form.useWatch('pharmacyTypeInput')

  const { data: pharmacyData, isLoading } = useGetQuestions(
    URL_GET_PHARMACY_TYPE,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangePharmacyType = useCallback((checked, row) => {
    const updatedParmacyType = {
      id: row.id,
      comment: `pharmacyTypeInput${row.id}` || null, // Set comment or null if no value provided
    }

    const numChecked = parmacySourceValue.length

    if (row.id === 6 && checked === true) {
      setDisableLast(true)
      setDisable(false)
      form.setFields([
        {
          name: ['changePharmacyType'],
          value: { PharmacyType6: { id: 6 } },
        },
      ])
    } else {
      setIsItem6Present(false)
      setDisableLast(false)
      setDisable(true)
    }

    if (numChecked < 2 && checked === false) {
      setDisableLast(false)
      setDisable(false)
    }

    if (checked) {
      form.setFieldsValue({
        householdPharmacyTypes: [
          ...form.getFieldValue('householdPharmacyTypes'),
          { id: row.id, comment: null },
        ],
      })
    } else {
      form.setFieldsValue({
        householdPharmacyTypes: form
          .getFieldValue('householdPharmacyTypes')
          .filter((r) => r.id !== row.id),
      })
    }

    setParmacySourceValue((prevState) => {
      // Check if the checkbox is checked or unchecked
      if (checked) {
        // If checked, update or add the item
        const existingIndex = prevState.findIndex(
          (action) => action.id === row.id,
        )

        if (existingIndex !== -1) {
          // If the item already exists, update it
          const updatedActions = [...prevState]
          updatedActions[existingIndex] = updatedParmacyType
          return updatedActions
        } else {
          // If the item doesn't exist, add it
          return [...prevState, updatedParmacyType]
        }
      } else {
        form.resetFields([`pharmacyTypeInput${row.id}`])
        // If unchecked, remove the item
        return prevState.filter((action) => action.id !== row.id)
      }
    })
  })

  const onInputChangepharmacyTypeInput = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdPharmacyTypes')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('householdPharmacyTypes')]
      newData[index].comment = value
      form.setFieldsValue({ householdPharmacyTypes: newData })
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Դուք կամ Ձեր ընտանիքի այն անդամները, որոնք ամենօրյա կտրվածքով դեղեր են ընդունում, որտեղի՞ց եք/ են ձեռք բերում դրանք"
        showRequired={true}
      >
        <FormItem name="householdPharmacyTypes" hidden initialValue={[]} />

        <FormItem name={['changePharmacyType']} rules={[{ required: true }]}>
          {pharmacyData?.map((row) => {
            return (
              <FormItem name={['changePharmacyType', row.label]} key={row.id}>
                <FormItem
                  name={['changePharmacyType', row.label, 'id']}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => {
                      setChecked(e.target.checked)
                      onChangePharmacyType(e.target.checked, row)

                      if (row.id === 5) {
                        form.resetFields([`pharmacyTypeInput${row.id}`])
                        setPharmacyTypeComment('')
                      }
                    }}
                    disabled={
                      disabled ||
                      (isItem6Present && row.id !== 6) ||
                      (row.id !== 6 ? disableLast : disable)
                    }
                    value={row.id}
                  >
                    {row.name}
                  </Checkbox>
                </FormItem>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    {selectPharmacyType?.[row.label]?.id ? (
                      row.hasComment === true ? (
                        <FormItem
                          // rules={[{ required: true }]}
                          rules={[
                            {
                              pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                              message:
                                'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                            },
                          ]}
                          name={`pharmacyTypeInput${row.id}`}
                          style={{
                            marginTop: '20px',
                            marginLeft: '25px',
                          }}
                        >
                          <Input
                            defaultValue={pharmacyTypeComment}
                            placeholder={'Նշել'}
                            onChange={(e) =>
                              onInputChangepharmacyTypeInput(
                                e.target.value,
                                row.id,
                              )
                            }
                            disabled={disabled}
                          />
                        </FormItem>
                      ) : (
                        <FormItem hidden />
                      )
                    ) : (
                      <FormItem hidden />
                    )}
                  </Col>
                </Row>
              </FormItem>
            )
          })}
        </FormItem>
      </FormSectionCard>
    </Spin>
  )
}

export default PharmacyType
