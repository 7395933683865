import { Col, Row, Space } from 'antd'
import { Button } from '.'
import { Form } from '../form/form'
import { useApplicationSteps } from '../../context/applicaton-steps-context'

export const SubmitButton = ({
  canceltext = 'Չեղարկել',
  submitText = 'Ավարտել',
  onCancel,
  showCancelButton = true,
  onSubmit,
  step,
  disabled,
}) => {
  const { jump } = useApplicationSteps()
  const hasAgreed = Form.useWatch('personAgreement')

  return (
    <Row align="center" justify="center">
      <Col span={8}>
        <Space
          size="middle"
          align="center"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          {showCancelButton && (
            <Button type="default" disabled={false} onClick={onCancel}>
              {canceltext}
            </Button>
          )}

          {disabled && step !== 14 && (
            <Button
              htmlType="button"
              onClick={() => {
                if (step === 11) {
                  jump(step + 2)
                } else {
                  jump(step + 1)
                }
              }}
              type="primary"
              disabled={false}
            >
              {'Հաջորդ'}
            </Button>
          )}

          {!disabled && (
            <Button
              htmlType="submit"
              type="primary"
              onClick={onSubmit}
              disabled={false}
            >
              {step === 0 && hasAgreed?.id !== 1 ? 'Հաջորդ' : submitText}
            </Button>
          )}
        </Space>
      </Col>
    </Row>
  )
}
