import { Button, Checkbox, Col, Divider, Form, Space, Spin } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { SmallInput } from '../../../input/small-input'
import { Select } from '../../../select'
import { COLORS } from '../../../../helpers/constants'
import { Text } from '../../../typography'
import { URL_GET_NON_REGISTERED_POLYCLINIC_REASON } from '../constants'
import { createOptionsFromArrayName } from '../../../../helpers/utils'
import { FormSectionCard } from '../../../card/form-section-card'

const PolyclinicServingChildren = ({ dataFamily, getData }) => {
  const form = Form.useFormInstance()
  const { data: nonRegisteredPolyclinicReason, isLoading } = useGetQuestions(
    URL_GET_NON_REGISTERED_POLYCLINIC_REASON,
  )

  useEffect(() => {
    if (getData && getData.householdNonRegisteredPolyclinicReasons) {
      const transformed = Object.values(
        getData?.householdNonRegisteredPolyclinicReasons
          ?.filter((item) => item.isChild === true && item.isAdult === false)
          ?.reduce((acc, item) => {
            const { personId, id, comment } = item

            if (!acc[personId]) {
              acc[personId] = {
                personId,
                personNonSafetyTypes: [],
              }
            }

            acc[personId].personNonSafetyTypes.push(id)
            if (comment) {
              acc[personId][`comment_${id}`] = comment
            }

            return acc
          }, {}),
      )

      const requestMemberItemsselectChildren =
        transformed?.map((safetyPeople) => ({
          personId: safetyPeople?.personId,
          personNonSafetyTypes: safetyPeople?.personNonSafetyTypes || [],
          ...safetyPeople?.personNonSafetyTypes?.reduce((acc, safetyType) => {
            acc[`comment_${safetyType}`] =
              safetyPeople[`comment_${safetyType}`] || ''
            return acc
          }, {}),
        })) || []

      const initialCheckedState = transformed?.reduce((acc, item, index) => {
        acc[index] = item.personNonSafetyTypes?.reduce((typeAcc, typeId) => {
          typeAcc[typeId] = true
          return typeAcc
        }, {})
        return acc
      }, {})

      setTimeout(() => {
        form.setFieldsValue({
          requestMemberItemsselectChildren,
        })
        setIsChecked(initialCheckedState)
      }, 300)
    }
  }, [getData, form])

  const CheckboxWithComment = ({
    type,
    formItemName,
    onChange,
    isChecked,
    comment,
  }) => (
    <VerticalSpace>
      <Space>
        <Checkbox
          value={type.id}
          onChange={(e) => onChange(e.target.checked, type.id)}
          checked={isChecked}
        >
          {type.name}
        </Checkbox>
        {type.hasComment && isChecked && (
          <Form.Item
            name={[...formItemName, `comment_${type.id}`]}
            initialValue={comment}
            rules={[
              {
                required: false,
                message: 'Please add a comment for this item.',
              },
              {
                pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
              },
            ]}
            noStyle
          >
            <SmallInput size="small" />
          </Form.Item>
        )}
      </Space>
    </VerticalSpace>
  )

  const renderItems = (fields, add, remove) => (
    <>
      {fields.map(({ key, name, ...restField }) => (
        <VerticalSpace key={key}>
          <FormSectionCard
            title={
              fields.length > 1 && name !== 0 ? (
                <Col
                  span={2}
                  style={{
                    alignSelf: 'center',
                    marginBottom: '-10px',
                  }}
                >
                  <Button
                    style={{ borderRadius: '0' }}
                    onClick={() => remove(name)}
                  >
                    <MinusOutlined />
                  </Button>
                </Col>
              ) : (
                <Col
                  span={2}
                  style={{
                    alignSelf: 'center',
                    marginBottom: '-10px',
                  }}
                >
                  <Button style={{ borderRadius: '0' }} onClick={() => add()}>
                    <PlusOutlined
                      style={{
                        color: COLORS.PRIMARY.BLUE,
                        padding: 0,
                      }}
                    />
                  </Button>
                </Col>
              )
            }
            showRequired={false}
            style={{ marginBottom: '20px' }}
          >
            <Space>
              <Form.Item
                {...restField}
                style={{ marginBottom: 20, width: 250 }}
                name={[name, 'personId']}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={'Նշել անդամի տվյալները'}
                  options={createOptionsFromArrayName(dataFamily)}
                />
              </Form.Item>
            </Space>

            <Form.Item
              label={
                <Text color={COLORS.PRIMARY.GRAY_DARK}>
                  Խնդրում եմ նշել՝ որոնք են հաշվառված չլինելու պատճառները
                </Text>
              }
              name={[name, 'personNonSafetyTypes']}
              rules={[{ required: true }]}
            >
              <Checkbox.Group style={{ marginLeft: 30 }}>
                <VerticalSpace>
                  {nonRegisteredPolyclinicReason?.map((type) => (
                    <CheckboxWithComment
                      key={type.id}
                      type={type}
                      formItemName={[name]}
                      onChange={(checked, id) =>
                        handleCheckboxChange(checked, id, name)
                      }
                      isChecked={isChecked[name] && isChecked[name][type.id]}
                      comment={form.getFieldValue([name, `comment_${type.id}`])}
                    />
                  ))}
                </VerticalSpace>
              </Checkbox.Group>
            </Form.Item>
            <Divider />
          </FormSectionCard>
        </VerticalSpace>
      ))}
    </>
  )

  const [isChecked, setIsChecked] = useState({})

  const handleCheckboxChange = (checked, id, name) => {
    setIsChecked((prevState) => {
      const newState = { ...prevState }
      if (!newState[name]) newState[name] = {}
      newState[name][id] = checked
      return newState
    })

    if (checked === false) {
      form.setFieldsValue({
        requestMemberItemsselectChildren: {
          [name]: {
            [`comment_${id}`]: '',
          },
        },
      })
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Form.List name="requestMemberItemsselectChildren" initialValue={[{}]}>
        {(fields, { add, remove }) => renderItems(fields, add, remove)}
      </Form.List>
    </Spin>
  )
}

export default PolyclinicServingChildren
