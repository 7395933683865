import React, { useEffect, useState } from 'react'
import { useApplicationSteps } from '../../context/applicaton-steps-context'
import FirstStepForm from '../../components/fill-application/first-step'
import SecondStepForm from '../../components/fill-application/second-step'
import ThirdStepForm from '../../components/fill-application/third-step'
import ForthStepForm from '../../components/fill-application/forth-step'
import { FifthStep } from '../../components/fill-application/fifth-step'
import EighthStepForm from '../../components/fill-application/eighth-step'
import { SeventhStep } from '../../components/fill-application/seventh-step'
import SixthStep from '../../components/fill-application/sixth-step'
import { NinthStep } from '../../components/fill-application/ninth-step'
// import { ThirteenthStep } from '../../components/fill-application/thirteenth-step'
import EleventhStep from '../../components/fill-application/eleventh-step'
import { TenthStep } from '../../components/fill-application/tenth-step'
import TwelfthStepForm from '../../components/fill-application/twelfth-step'
import FourteenStep from '../../components/fill-application/fourteen-step'
import FifteenStep from '../../components/fill-application/fifteen-step'
import { Form } from '../../components/form/form'
import { useLocation } from 'react-router-dom'
import { steps } from '../../components/steps/application-steps'
import { useGetPublic } from '../../api/application/use-get-public'
import { URL_GET_FIRST_PAGE_DATA } from '../../api/application/constants'

const ManageSteps = () => {
  const form = Form.useFormInstance()
  const location = useLocation()
  const { step, setStep } = useApplicationSteps()
  const [shouldFetch, setShouldFetch] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const { householdId, status, disabled } = location.state || {}
  const [getHouseHold, setHouseHold] = useState(householdId)
  const stepUrl = steps[step]?.url
  const householdId1 = form.getFieldValue('appId')

  useEffect(() => {
    const resolvedHouseholdId = householdId ? householdId : householdId1
    if (resolvedHouseholdId) {
      setShouldFetch(true)
      setHouseHold(resolvedHouseholdId)
      form.setFieldsValue({ appId: resolvedHouseholdId })
    }

    // if (step === 12) {
    //   setStep(13)
    // }
    if (stepUrl) {
      setShouldFetch(true)
    }
  }, [step, setStep, stepUrl, householdId, householdId1, form])

  const { data, isFetching } = useGetPublic(
    shouldFetch && (stepUrl ? stepUrl : URL_GET_FIRST_PAGE_DATA),
    {
      householdId: getHouseHold,
    },
    {
      // enabled: shouldFetch && !!getHouseHold && (status === 1 || status === 2),
      enabled: shouldFetch && !!getHouseHold,
    },
  )

  useEffect(() => {
    if (getHouseHold) {
      form.setFieldsValue({
        appId: getHouseHold,
      })
    }
  }, [form, getHouseHold, householdId])

  return (
    <>
      {/*for edit or create*/}
      {isFetching && <div>Loading...</div>}
      {!isFetching && step === 0 && (
        <FirstStepForm getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 1 && (
        <SecondStepForm getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 2 && (
        <ThirdStepForm getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 3 && (
        <ForthStepForm getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 4 && (
        <FifthStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 5 && (
        <SixthStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 6 && (
        <SeventhStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 7 && (
        <EighthStepForm getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 8 && (
        <NinthStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 9 && (
        <TenthStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 10 && (
        <EleventhStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 11 && (
        <TwelfthStepForm
          getData={data}
          disabled={disabled}
          getHouseHold={getHouseHold}
        />
      )}
      {/*{!isFetching && step === 12 && <ThirteenthStep />}*/}
      {!isFetching && step === 13 && (
        <FourteenStep getData={data} disabled={disabled} />
      )}
      {!isFetching && step === 14 && (
        <FifteenStep getData={data} disabled={disabled} />
      )}
    </>
  )
}

export default ManageSteps
