import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions, {
  URL_GET_NON_WORK_REASON,
} from '../../../api/application/use-get-questions'
import { Checkbox, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { Text, Title } from '../../typography'
import { Input } from '../../input'
import { Form } from '../../form/form'

const createName = (id) => `item${id}`

const NonWorkReasons = ({ field, disabled }) => {
  const { data: questions, isLoading } = useGetQuestions(
    URL_GET_NON_WORK_REASON,
  )
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = createName(item.id)
          const isChecked = form.getFieldValue([
            'personWorks',
            field.name,
            'nonWorkReasons',
            itemName,
            'isActive',
          ])
          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children) // Recursively handle children
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const initialCheckedIds = setInitialCheckedState(questions)
    setCheckedIds(initialCheckedIds)
  }, [form, field.name, questions])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        setErrorMessage('')
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          setErrorMessage('')
          return true
        }
      }
    }
    setErrorMessage('Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը')
    return false
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uncheckChildrenNon = useCallback((children) => {
    children.forEach((child) => {
      const childName = createName(child.id)
      setCheckedIds((prev) => ({ ...prev, [childName]: false }))
      form.setFields([
        {
          name: ['personWorks', field.name, 'nonWorkReasons', childName, 'id'],
          value: false,
        },
      ])
      if (child.children) {
        uncheckChildrenNon(child.children)
      }
    })
  })

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, children) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['personWorks', field.name, 'nonWorkReasons', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [
              'personWorks',
              field.name,
              'nonWorkReasons',
              itemName,
              'comment',
            ],
            value: '',
          },
        ])

        if (children) {
          uncheckChildrenNon(children)
        }
      }
    },
    [form, field.name, uncheckChildrenNon],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'nonWorkReasons', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  item.children,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'nonWorkReasons', itemName, 'comment']}
              rules={[
                { required: true },
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              style={{ marginLeft }}
            >
              <Input placeholder="Նշել" disabled={disabled} />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem
        name={'nonWorkReasons'}
        label={<Title>Խնդրում եմ նշել պատճառները</Title>}
      >
        {renderItems(questions)}
        <Text color="red">{errorMessage}</Text>
      </FormItem>
    </Spin>
  )
}

export default NonWorkReasons
