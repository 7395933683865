import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import FormItem from 'antd/es/form/FormItem'
import useGetQuestions from '../../../api/application/use-get-questions'
import { GET_RESIDENCE_CONDITION } from './constants'
import { Radio, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { Title } from '../../typography'
import { TextArea } from '../../input'
import { COLORS } from '../../../helpers/constants'
import { BoldText } from '../../typography/text'

const HandleAnswer = ({ disabled }) => {
  const { data: questions } = useGetQuestions(GET_RESIDENCE_CONDITION)
  return (
    <FormItem name="householdResidenceConditions">
      {questions.map((question) => (
        <VerticalSpace key={question.id}>
          <FormItem name={['householdResidenceConditions', question.id]}>
            <FormItem
              name={['householdResidenceConditions', question.id, 'id']}
              initialValue={question.id}
              hidden
            >
              <input disabled={disabled} />
            </FormItem>
            {question.id === 1 && (
              <Title>
                <BoldText color={COLORS.PRIMARY.BLUE}>
                  Վտանգի նշաններ. օրինակ՝ բաց էլեկտրալարերի առկայություն, առանց
                  վանդակաճաղերի կամ բացակայող աստիճաններով աստիճանավանդակներ,
                  չսահմանազատված պաշգամբ և այլն
                </BoldText>
              </Title>
            )}
            <FormItem
              rules={[{ required: true }]}
              name={['householdResidenceConditions', question.id, 'answerId']}
              label={
                <Title>
                  {question.id}. {question.name}
                </Title>
              }
            >
              <Radio.Group style={{ marginTop: 20 }} disabled={disabled}>
                <VerticalSpace>
                  {question?.children?.map((answer) => (
                    <Radio key={answer.id} value={answer.id}>
                      {answer.name}
                    </Radio>
                  ))}
                </VerticalSpace>
              </Radio.Group>
            </FormItem>
          </FormItem>
        </VerticalSpace>
      ))}
    </FormItem>
  )
}

export const RecidenceConditions = ({ disabled }) => {
  const { data: questions, isLoading } = useGetQuestions(
    GET_RESIDENCE_CONDITION,
  )

  return (
    <Spin spinning={isLoading}>
      <FormItem name="householdResidenceConditions" hidden initialValue={[]} />
      <FormSectionCard showRequired={false} title="Բնակության վայրի պայմանները">
        <Title
          style={{
            marginBottom: 5,
          }}
          color={COLORS.SECONDARY.YELLOW}
        >
          Գնահատման ընթացքում դիտարկման հիման վրա գնահատեք, թե որքանով են նշված
          պայմաններն ապահովված և վիճակի վտանգավորությունը բնակիչների համար
          առաջարկված սանդղակով, ավելացրեք նկատառումներ վերջին կետում
        </Title>
        <HandleAnswer disabled={disabled} />
        <Title>{questions.length + 1}. Լրացուցիչ նշումներ</Title>
        <FormItem
          rules={[
            {
              pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
              message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
            },
          ]}
          name={['comment']}
          initialValue={''}
        >
          <TextArea
            disabled={disabled}
            style={{ marginTop: 20 }}
            placeholder="Նշել"
          />
        </FormItem>
      </FormSectionCard>
    </Spin>
  )
}
