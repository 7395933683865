import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  nonPsychicSupportReasons,
  psychicSpecialistTypes,
  psychicSupportTerminationReasons,
} from '../../../fill-application/constatns'
import { FormSectionFullCard } from '../../../card/form-section-full-card'
import { FormItem } from '../../form-item'
import { Checkbox, Input, Radio } from 'antd'
import { SecondaryText, Title } from '../../../typography'
import { Form } from '../../form'

const createName = (id) => `item${id}`

const PsychicSupportForm = ({ parentProp, field, getData, disabled }) => {
  const [specialstIds, setSpecialistIds] = useState({})
  const [terminationIds, setTerminationtIds] = useState({})
  const [notApplingIds, setNotApplingIds] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const familyMembers = getData?.familyData?.familyMembers || []

      const initialCheckedState = familyMembers.reduce((acc, item) => {
        item?.personPsychicSupportTerminationReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            id: supportType.id,
            comment: supportType?.comment || '',
          }
        })
        return acc
      }, {})

      const initialCheckedStateNonPsychic = familyMembers.reduce(
        (acc, item) => {
          item?.personNonPsychicSupportReasons?.forEach((supportType) => {
            acc[`item${supportType.id}`] = {
              id: supportType.id,
              comment: supportType?.comment || '',
            }
          })
          return acc
        },
        {},
      )

      const initialCheckedPsychicSupportTypes = familyMembers.reduce(
        (acc, item) => {
          item?.personPsychicSupportTypes?.forEach((supportType) => {
            acc[`item${supportType.id}`] = {
              id: supportType.id,
              comment: supportType?.comment || '',
            }
          })
          return acc
        },
        {},
      )

      setTimeout(() => {
        form.setFieldsValue({
          familyData: {
            ...form.getFieldsValue(true).familyData,
            familyMembers: {
              ...form.getFieldsValue(true).familyData.familyMembers,
              [field.name]: {
                ...form.getFieldsValue(true).familyData.familyMembers[
                  field.name
                ],
                personPsychicSupportTypes:
                  initialCheckedPsychicSupportTypes || {},
                personNonPsychicSupportReasons:
                  initialCheckedStateNonPsychic || {},
                personPsychicSupportTerminationReasons:
                  initialCheckedState || {},
              },
            },
          },
        })

        // Update state with the initial values
        setSpecialistIds(initialCheckedPsychicSupportTypes || {})
        setTerminationtIds(initialCheckedState || {})
        setNotApplingIds(initialCheckedStateNonPsychic || {})
      }, 700)
    }
  }, [field.name, form, getData])

  const psychicInjury = Form.useWatch(
    [...parentProp, field.name, 'psychicInjury'],
    {
      preserve: true,
    },
  )
  const psychicSupportId = Form.useWatch(
    [...parentProp, field.name, 'psychicSupportId'],
    {
      preserve: true,
    },
  )

  const specSupport = Form.useWatch(
    [...parentProp, field.name, 'continuePsychicSupport'],
    {
      preserve: true,
    },
  )

  useEffect(() => {
    // item7 - Մասնագիտական աջակցության կարիքն այլևս չկա
    if (notApplingIds?.item7 && Object.keys(notApplingIds).length > 1) {
      form.setFields([
        {
          name: [...parentProp, field.name, 'personNonPsychicSupportReasons'],
          value: { item7: { id: 7 } },
        },
      ])
      setNotApplingIds({ item7: true })
    } else if (notApplingIds?.item7) {
      setDisableCheckboxes(true)
      return
    } else {
      setDisableCheckboxes(false)
    }
  }, [field.name, form, notApplingIds, parentProp])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, itemProp, stateKey) => {
      setState((prevCheckedIds) => {
        const updatedState = { ...prevCheckedIds }
        if (checked) {
          updatedState[itemName] = { id: itemId }
        } else {
          delete updatedState[itemName]
        }
        return updatedState
      })
      const updatedSpecialistIds = { ...stateKey }
      if (checked) {
        updatedSpecialistIds[itemName] = { id: itemId }
      } else {
        delete updatedSpecialistIds[itemName]
      }
      const updatedFieldValues = Object.values(updatedSpecialistIds)
      form.setFields([
        {
          name: ['familyData', 'familyMembers', field.name, itemProp],
          value: updatedFieldValues,
        },
      ])
    },
    [form, field],
  )

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleInputChange = useCallback(
    (itemName, value, setState, itemProp) => {
      setState((prevCheckedIds) => {
        const updatedState = { ...prevCheckedIds }
        if (updatedState[itemName]) {
          updatedState[itemName] = {
            ...updatedState[itemName],
            comment: value,
          }
        }
        form.setFields([
          {
            name: ['familyData', 'familyMembers', field.name, itemProp],
            value: updatedState,
          },
        ])
        return updatedState
      })
    },
    [form, field.name],
  )

  const renderItems = useMemo(
    () =>
      (items, level = 0, state, setState, itemProp) => {
        const marginLeft = level * 20
        return items.map((item) => {
          const itemName = createName(item.id)
          return (
            <React.Fragment key={item.id}>
              <FormItem
                name={[field.name, itemProp, itemName]}
                style={{ marginLeft }}
                rules={[
                  {
                    validator: () =>
                      validateCheckboxGroup(items, state)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                            ),
                          ),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Checkbox
                  disabled={
                    disabled ||
                    (itemProp === 'personNonPsychicSupportReasons' &&
                      disableCheckboxes &&
                      item.id !== 7)
                  }
                  checked={!!state[itemName]}
                  onChange={(e) =>
                    handleCheckboxChange(
                      itemName,
                      e.target.checked,
                      item.id,
                      setState,
                      itemProp,
                      state,
                    )
                  }
                >
                  {item.label}
                </Checkbox>
              </FormItem>
              {item.hasComment && state[itemName] && (
                <FormItem
                  name={[field.name, itemProp, itemName, 'comment']}
                  rules={[
                    // { required: true },
                    {
                      pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                      message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                    },
                  ]}
                  style={{ marginLeft }}
                >
                  <Input
                    defaultValue={state[itemName]?.comment || ''}
                    onChange={(e) =>
                      handleInputChange(
                        itemName,
                        e.target.value,
                        setState,
                        itemProp,
                      )
                    }
                    disabled={disabled}
                    placeholder="Նշել"
                  />
                </FormItem>
              )}
              {item.children &&
                item.children.length > 0 &&
                state[itemName] &&
                renderItems(item.children, level + 1, itemName)}
            </React.Fragment>
          )
        })
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.name, disableCheckboxes, handleCheckboxChange],
  )

  return (
    <FormSectionFullCard>
      <FormItem
        name={[field.name, 'psychicInjury']}
        label={
          <Title>
            Արդյոք բռնի տեղահանման հետևանքով/ընթացքում ձեռք է բերել հոգեկան
            առողջության խնդիրներ կամ ենթադրում եք, որ նրա մոտ նկատվող
            փոփոխությունը կարող է հոգեկան առողջության խնդիր լինել կամ վերածվել
            հոգեկան առողջության խնդրի
          </Title>
        }
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={() => {
            form.resetFields([
              ['familyData', 'familyMembers', field.name, 'psychicSupportId'],
              [field.name, 'personPsychicSupportTypes'],
              [
                'familyData',
                'familyMembers',
                field.name,
                'continuePsychicSupport',
              ],
              [field.name, 'personPsychicSupportTerminationReasons'],
              [field.name, 'personNonPsychicSupportReasons'],
            ])
            setSpecialistIds({})
            setTerminationtIds({})
            setNotApplingIds({})
          }}
          disabled={disabled}
        >
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {psychicInjury && (
        <FormItem
          name={[field.name, 'psychicSupportId']}
          rules={[{ required: true }]}
          label={<Title>Արդյո՞ք դիմել է մասնագիտական աջակցության</Title>}
        >
          <Radio.Group
            onChange={() => {
              form.resetFields([
                [field.name, 'personPsychicSupportTypes'],
                [
                  'familyData',
                  'familyMembers',
                  field.name,
                  'continuePsychicSupport',
                ],
                [field.name, 'personPsychicSupportTerminationReasons'],
                [field.name, 'personNonPsychicSupportReasons'],
              ])
              setSpecialistIds({})
              setTerminationtIds({})
              setNotApplingIds({})
            }}
            disabled={disabled}
          >
            <Radio value={1}>Այո</Radio>
            <Radio value={2}>Ոչ</Radio>
            <Radio value={3}>Հրաժարվում է պատասխանել</Radio>
          </Radio.Group>
        </FormItem>
      )}
      {psychicSupportId === 1 && psychicInjury && (
        <>
          <FormItem
            name={[field.name, 'personPsychicSupportTypes']}
            label={<SecondaryText>Ի՞նչ մասնագետի է դիմել</SecondaryText>}
          >
            {renderItems(
              psychicSpecialistTypes,
              0,
              specialstIds,
              setSpecialistIds,
              'personPsychicSupportTypes',
            )}
          </FormItem>
          <FormItem
            name={[field.name, 'continuePsychicSupport']}
            label={
              <SecondaryText>
                Արդյո՞ք շարունակում է մասնագիտական աջակցություն ստանալ
              </SecondaryText>
            }
            rules={[{ required: true }]}
          >
            <Radio.Group
              onChange={() => {
                form.resetFields([
                  [field.name, 'personPsychicSupportTerminationReasons'],
                ])
                setTerminationtIds({})
              }}
              disabled={disabled}
            >
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </FormItem>
          {specSupport === false && (
            <FormItem
              name={[field.name, 'personPsychicSupportTerminationReasons']}
              label={<SecondaryText>Որն է դադարեցման պատճառը</SecondaryText>}
            >
              {renderItems(
                psychicSupportTerminationReasons,
                0,
                terminationIds,
                setTerminationtIds,
                'personPsychicSupportTerminationReasons',
              )}
            </FormItem>
          )}
        </>
      )}
      {psychicSupportId === 2 && psychicInjury && (
        <FormItem
          name={[field.name, 'personNonPsychicSupportReasons']}
          label={
            <SecondaryText>
              Ո՞րն է մասնագիտական աջակցության չդիմելու պատճառը
            </SecondaryText>
          }
        >
          {renderItems(
            nonPsychicSupportReasons,
            0,
            notApplingIds,
            setNotApplingIds,
            'personNonPsychicSupportReasons',
          )}
        </FormItem>
      )}
    </FormSectionFullCard>
  )
}

export default PsychicSupportForm
