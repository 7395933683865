import { Checkbox, Form, Space } from 'antd'
import { useEffect, useState } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import { SmallInput } from '../../input/small-input'

const NestedCheckboxes = ({
  row,
  level,
  onCheckboxChange,
  onInputChange,
  form,
  chechboxName,
  id,
}) => {
  const name = `comment_${id}_${row?.id}`
  const [checkedList, setCheckedList] = useState(
    form?.getFieldValue(chechboxName) || [],
  )
  const hasChildren = !!row.children.length

  const isChecked = Array.isArray(checkedList) && checkedList.includes(row.id)

  useEffect(() => {
    if (!isChecked) {
      form.resetFields([name])
    }
  }, [isChecked, name, form])

  return (
    <VerticalSpace>
      <Space>
        <Checkbox
          checked={isChecked}
          onChange={(e) => {
            const newCheckedList = e.target.checked
              ? [...checkedList, row.id]
              : checkedList.filter((value) => value !== row.id)

            setCheckedList(newCheckedList)
            onCheckboxChange(newCheckedList, row)
          }}
          value={row.id}
          style={{ marginLeft: 30 * level }}
        >
          {row.name}
        </Checkbox>

        {row.hasComment && isChecked && (
          <Form.Item
            name={name}
            noStyle
            rules={[
              { required: true },
              {
                pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
              },
              // Add pattern validation if needed
            ]}
          >
            <SmallInput
              size="small"
              onChange={(e) => onInputChange(e.target.value, row, id)}
            />
          </Form.Item>
        )}
      </Space>

      {hasChildren &&
        isChecked &&
        row.children.map((child) => (
          <NestedCheckboxes
            key={child.id}
            row={child}
            level={level + 1}
            onCheckboxChange={onCheckboxChange}
            onInputChange={onInputChange}
            chechboxName={chechboxName}
            id={id}
          />
        ))}
    </VerticalSpace>
  )
}

export default NestedCheckboxes
