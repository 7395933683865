import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormSectionFullCard } from '../../../card/form-section-full-card'
import { Title } from '../../../typography'
import { FormItem } from '../../form-item'
import { Checkbox } from 'antd'
import { Form } from '../../form'
import { Input } from '../../../input'
import { childNonResideReasons } from '../../../fill-application/constatns'
import { VerticalSpace } from '../../../space/vertical-space'

const createName = (id) => `item${id}`

const ChildNonReside = ({ parentProp, field, getData, disabled }) => {
  const form = Form.useFormInstance()
  const [checkIds, setCheckIds] = useState({})

  const initialState = useMemo(() => {
    if (getData) {
      const displacedPersons = getData?.familyData?.displacedPersons || []

      const allPersons = [...displacedPersons]

      const initialCheckedState = allPersons.reduce((acc, item) => {
        item?.personChildNotWithParentTypes?.forEach((parentTypes) => {
          acc[`item${parentTypes.id}`] = {
            // ...parentTypes,
            id: parentTypes?.id,
            comment: parentTypes?.comment,
          }
        })
        return acc
      }, {})

      return {
        initialCheckedState,
      }
    }
    return {}
  }, [getData])

  useEffect(() => {
    if (initialState) {
      form.setFieldsValue({
        familyData: {
          ...form.getFieldsValue(true).familyData,
          displacedPersons: {
            ...form.getFieldsValue(true).familyData.displacedPersons,
            [field.name]: {
              ...form.getFieldsValue(true).familyData.displacedPersons[
                field.name
              ],
              personChildNotWithParentTypes:
                initialState.initialCheckedState || {},
            },
          },
        },
      })
      setCheckIds(initialState.initialCheckedState || {})
    }
  }, [field.name, form, initialState])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, itemProp) => {
      setCheckIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [...parentProp, field.name, itemProp, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [...parentProp, field.name, itemProp, itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, parentProp, field.name],
  )

  const renderItems = (items, level = 0, itemProp) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, itemProp, itemName]}
            style={{ marginLeft }}
          >
            <Checkbox
              checked={checkIds[itemName]}
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  itemProp,
                )
              }
            >
              {item.label}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkIds[itemName] && (
            <FormItem
              name={[field.name, itemProp, itemName, 'comment']}
              rules={[
                { required: true },
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              style={{ marginLeft }}
            >
              <Input
                placeholder="Նշել"
                value={checkIds[itemName]?.comment}
                disabled={disabled}
              />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <Title>Ինչո՞ւ երեխան ծնողների կամ խնամակալների հետ չէ</Title>

        <FormItem name={[field.name, 'personChildNotWithParentTypes']}>
          {renderItems(
            childNonResideReasons,
            0,
            'personChildNotWithParentTypes',
          )}
        </FormItem>
      </VerticalSpace>
    </FormSectionFullCard>
  )
}

export default ChildNonReside
