import { VerticalSpace } from '../../space/vertical-space'
import InformationLanguage from './information-language'
import InformationReceiveType from './information-receive-type'
import InformationAvailability from './information-availability'
import SupportDirection from './support-direction'
import { Form } from 'antd'
// import { useGetPublic } from '../../../api/application/use-get-public'
// import { URL_GET_FOURTEEN_PAGE_DATA } from '../../../api/application/constants'
import { useEffect } from 'react'

const FourteenStep = ({ getData, disabled = false }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        ...getData,
        ...getData?.householdSupportDirections?.reduce((acc, type) => {
          // Set radio value (true/false)
          if (type?.id !== undefined) {
            acc[`radio_${type.id}`] = type?.hasInformation
          }

          // Handle checkboxes
          if (Array.isArray(type?.householdSupportDirectionInformations)) {
            acc[`checkbox_${type.id}`] =
              type?.householdSupportDirectionInformations
                ?.filter((info) => info.isActive)
                ?.map((info) => info.id)

            // Set comments for active checkboxes
            type?.householdSupportDirectionInformations?.forEach((info) => {
              if (info?.id && info?.comment) {
                // Setting the comment for each checkbox id
                acc[`comment_${type.id}_${info.id}`] = info.comment
              }
            })
          } else {
            acc[`checkbox_${type.id}`] = [] // Default to empty array if no checkboxes
          }

          return acc
        }, {}),
      })
    } else {
      const radioFields = Array.from({ length: 28 }, (_, i) => `radio_${i + 1}`)
      const checkboxFields = Array.from(
        { length: 28 },
        (_, i) => `checkbox_${i + 1}`,
      )
      const additionalFields = [
        'householdSupportDirections',
        'householdInformationReceiveTypes',
        'householdInformationLanguages',
        'householdInformationAccessibilityNeeds',
      ]

      const allFields = [...radioFields, ...checkboxFields, ...additionalFields]

      form.resetFields(allFields)
    }
  }, [form, getData])
  return (
    <VerticalSpace>
      <SupportDirection getData={getData} disabled={disabled} />
      <InformationReceiveType getData={getData} />
      <InformationLanguage getData={getData} />
      <InformationAvailability getData={getData} />
    </VerticalSpace>
  )
}

export default FourteenStep
