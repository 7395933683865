/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormItem } from '../../form-item'
import { SecondaryText } from '../../../typography'
import { Form } from '../../form'
import { Checkbox } from '../../../checkbox'
import { Input } from '../../../input'
import { nonResideReasons } from '../../../fill-application/constatns'
import { Select } from '../../../select'
import { createOptionsFromArray } from '../../../../helpers/utils'
import { Radio } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
// import RedCrossAgreement from './agree-to-pass-red-cross'

const createName = (id) => `item${id}`

const NonResideReasons = ({ field, parentFormItem, getData }) => {
  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const nonResidePersons = getData?.familyData?.nonResidePersons || []

      const allPersons = [...nonResidePersons]

      const initialCheckedState = allPersons.reduce((acc, item) => {
        item?.personNotResideReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            // ...supportType
            id: supportType?.id,
            agreeRedCross: supportType?.agreeRedCross,
            applyRedCrossTypeId: supportType?.applyRedCrossTypeId,
            comment: supportType?.comment || '',
          }
        })
        return acc
      }, {})

      setTimeout(() => {
        form.setFieldsValue({
          familyData: {
            ...form.getFieldsValue(true).familyData,
            nonResidePersons: {
              ...form.getFieldsValue(true).familyData.nonResidePersons,
              [field.name]: {
                ...form.getFieldsValue(true).familyData.nonResidePersons[
                  field.name
                ],
                personNotResideReasons: initialCheckedState,
              },
            },
          },
        })
        setCheckedIds(initialCheckedState)
      }, 300)
    }
  }, [field.name, form, getData])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, fieldName, children = []) => {
      setCheckedIds((prev) => ({
        ...prev,
        [itemName]: checked
          ? {
              id: itemId,
              applyRedCrossTypeId: undefined,
              agreeRedCross: undefined,
            }
          : false,
      }))

      const updatedFormValues = {
        ...form.getFieldValue([
          ...parentFormItem,
          field.name,
          'personNotResideReasons',
        ]),
        [itemName]: checked ? { id: itemId } : false,
      }

      form.setFieldsValue({
        familyData: {
          ...form.getFieldValue('familyData'),
          nonResidePersons: {
            ...form.getFieldValue(['familyData', 'nonResidePersons']),
            [field.name]: {
              ...form.getFieldValue([
                'familyData',
                'nonResidePersons',
                field.name,
              ]),
              personNotResideReasons: updatedFormValues,
            },
          },
        },
      })
      // Resetting comment when parent is unchecked
      if (!checked) {
        const resetChildFormValues = (childItems) => {
          childItems.forEach((child) => {
            const childName = createName(child.id)
            form.setFields([
              {
                name: [
                  ...parentFormItem,
                  field.name,
                  'personNotResideReasons',
                  childName,
                  'id',
                ],
                value: false,
              },
              {
                name: [
                  ...parentFormItem,
                  field.name,
                  'personNotResideReasons',
                  childName,
                  'comment',
                ],
                value: '',
              },
            ])
            if (child.children) {
              resetChildFormValues(child.children) // Recursively reset children form values
            }
          })
        }
        resetChildFormValues(children)
      }
    },
    [form, parentFormItem, field.name],
  )

  const handleCheckboxChangeSelect = useCallback(
    (itemName, itemId, value) => {
      const updatedCheckedIds = {
        ...checkedIds,
        [itemName]: {
          ...checkedIds[itemName],
          id: itemId,
          applyRedCrossTypeId: value,
        },
      }

      setCheckedIds(updatedCheckedIds)

      form.setFieldsValue({
        familyData: {
          ...form.getFieldValue('familyData'),
          nonResidePersons: {
            ...form.getFieldValue(['familyData', 'nonResidePersons']),
            [field.name]: {
              ...form.getFieldValue([
                'familyData',
                'nonResidePersons',
                field.name,
              ]),
              personNotResideReasons: updatedCheckedIds,
            },
          },
        },
      })
    },
    [checkedIds, field.name, form],
  )

  const handleCheckboxChangeRadio = useCallback(
    (itemName, itemId, value) => {
      const updatedCheckedIds = {
        ...checkedIds,
        [itemName]: {
          ...checkedIds[itemName],
          id: itemId,
          agreeRedCross: value,
        },
      }

      setCheckedIds(updatedCheckedIds)
      form.setFields([
        {
          name: [...parentFormItem, field.name, 'personNotResideReasons'],
          value: updatedCheckedIds,
        },
      ])
    },
    [checkedIds, field.name, form, parentFormItem],
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={
              level !== 0 && item.type === 'select'
                ? [
                    field.name,
                    'personNotResideReasons',
                    itemName,
                    'applyRedCrossTypeId',
                  ]
                : level !== 0 && item.passRedCross
                  ? [
                      field.name,
                      'personNotResideReasons',
                      itemName,
                      'agreeRedCross',
                    ]
                  : [field.name, 'personNotResideReasons', itemName]
            }
            style={{ marginLeft }}
            label={
              item.title ? <SecondaryText>{item.title}</SecondaryText> : ''
            }
            rules={
              level !== 0 && item.type === 'select'
                ? [
                    {
                      required:
                        checkedIds[itemName]?.applyRedCrossTypeId === undefined,
                    },
                  ]
                : level !== 0 && item.type === 'radio'
                  ? [
                      {
                        required:
                          checkedIds[itemName]?.agreeRedCross === undefined,
                      },
                    ]
                  : [
                      {
                        validator: () =>
                          validateCheckboxGroup(items)
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                                ),
                              ),
                        validateTrigger: 'onSubmit',
                      },
                    ]
            }
          >
            {level !== 0 && item.type === 'select' ? (
              <Select
                options={createOptionsFromArray(item.options)}
                defaultValue={checkedIds[itemName]?.applyRedCrossTypeId || ''}
                onChange={(e) =>
                  handleCheckboxChangeSelect(itemName, item.id, e)
                }
              />
            ) : level !== 0 && item.type === 'radio' ? (
              <VerticalSpace>
                <SecondaryText>{item.name}</SecondaryText>
                <Radio.Group
                  value={checkedIds[itemName]?.agreeRedCross}
                  onChange={(e) =>
                    handleCheckboxChangeRadio(itemName, item.id, e.target.value)
                  }
                >
                  <Radio value={true}>Այո</Radio>
                  <Radio value={false}>Ոչ</Radio>
                </Radio.Group>
              </VerticalSpace>
            ) : (
              <Checkbox
                checked={checkedIds[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(
                    itemName,
                    e.target.checked,
                    item.id,
                    'id',
                  )
                }
              >
                {item.label}
              </Checkbox>
            )}
          </FormItem>

          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'personNotResideReasons', itemName, 'comment']}
              // rules={[{ required: true }]}
              rules={[
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              style={{ marginLeft }}
              label={item?.inputLabel || ''}
            >
              <Input
                placeholder="Նշել"
                defaultValue={checkedIds[itemName]?.comment || ''}
              />
            </FormItem>
          )}

          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormItem
      label={
        <SecondaryText>
          Խնդրում եմ նշել, ձեզ հետ ներկայումս չլինելու (այս կացարանում
          չբնակվելու) պատճառ/ները
        </SecondaryText>
      }
      name={[...parentFormItem, field.name, 'personNotResideReasons']}
    >
      {renderItems(nonResideReasons)}
    </FormItem>
  )
}

export default NonResideReasons
