import React, { useEffect, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { Form } from '../../../form/form'
import { FormItem } from '../../../form/form-item'
import { Input } from '../../../input'
// import { VerticalSpace } from '../../../space/vertical-space'
import { URL_BAD_ATTITUDE_TYPES } from '../constants'
import { Checkbox } from 'antd'
import AttitudeManifestation from './attitude-manifestation'
import { Title } from '../../../typography'

const createName = (id) => `item${id}`

const AttitudeTypes = ({ parentFormItem }) => {
  const { data: types } = useGetQuestions(URL_BAD_ATTITUDE_TYPES)
  const form = Form.useFormInstance()

  const [checkedIds, setCheckedIds] = useState({})

  const handleCheckboxChange = (itemName, checked, itemId) => {
    setCheckedIds((prevCheckedIds) => ({
      ...prevCheckedIds,
      [itemName]: checked,
    }))

    form.setFields([
      {
        name: [...parentFormItem, 'householdBadAttitudeTypes', itemName, 'id'],
        value: checked ? itemId : false,
      },
    ])

    if (!checked) {
      form.resetFields([
        [
          ...parentFormItem,
          'householdBadAttitudeTypes',
          itemName,
          'householdBadAttitudeDemonstrationTypes',
        ],
        [...parentFormItem, 'householdBadAttitudeTypes', itemName, 'comment'],
      ])
    }
  }

  // Handle comment changes
  const handleCommentChange = (itemName, comment) => {
    form.setFields([
      {
        name: [
          ...parentFormItem,
          'householdBadAttitudeTypes',
          itemName,
          'comment',
        ],
        value: comment,
      },
    ])
  }

  // Initialize the form's checked values for editing
  useEffect(() => {
    if (!types) return

    const initialCheckedIds = {}
    const initializeState = (items) => {
      items.forEach((item) => {
        const itemName = createName(item.id)
        const isChecked = form.getFieldValue([
          ...parentFormItem,
          'householdBadAttitudeTypes',
          itemName,
          'id',
        ])
        initialCheckedIds[itemName] = !!isChecked

        // Handle nested items if present
        if (item.children) initializeState(item.children)
      })
    }

    initializeState(types)
    setCheckedIds(initialCheckedIds)
  }, [form, parentFormItem, types])

  // Render each checkbox item with optional comment and nested components
  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      const isChecked = checkedIds[itemName]

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentFormItem, 'householdBadAttitudeTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  Object.values(checkedIds).some(Boolean)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={isChecked}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && isChecked && (
            <FormItem
              name={[
                ...parentFormItem,
                'householdBadAttitudeTypes',
                itemName,
                'comment',
              ]}
              label={'Նշել'}
              style={{ marginLeft }}
            >
              <Input
                defaultValue={form.getFieldValue([
                  ...parentFormItem,
                  'householdBadAttitudeTypes',
                  itemName,
                  'comment',
                ])}
                onChange={(e) => handleCommentChange(itemName, e.target.value)}
              />
            </FormItem>
          )}
          {isChecked && (
            <AttitudeManifestation
              parentFormItem={[
                ...parentFormItem,
                'householdBadAttitudeTypes',
                itemName,
              ]}
            />
          )}
          {item.children && renderItems(item.children, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <div style={{ paddingLeft: 30 }}>
      <Title style={{ margin: '12px 0' }}>
        Որո՞նք են եղել նման վերաբերմունքի հիմքերը
      </Title>
      <FormItem name={[...parentFormItem, 'householdBadAttitudeTypes']}>
        {types && renderItems(types)}
      </FormItem>
    </div>
  )
}

export default AttitudeTypes
