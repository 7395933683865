import React, { useEffect, useRef } from 'react'
import { Form } from '../../form/form'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from '../../button'
import PhoneNumberForm from './phone-number-form'
import { SecondaryText, Title } from '../../typography'
import { Info } from '../../typography/info'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

const PhoneNumberListForm = ({ disabled }) => {
  const form = Form.useFormInstance()

  const hasNotPhoneNumber = Form.useWatch('hasNotPhoneNumber', {
    preserve: true,
  })

  const onPhoneCheck = (isChecked) => {
    form.setFieldsValue({
      phoneNumberList: isChecked ? [] : form.getFieldValue('phoneNumberList'),
    })
  }

  return (
    <>
      <Title style={{ marginBottom: '20px' }}>
        Խնդրում եմ նշել Ձեր (և/կամ ձեզ հետ բնակվող ընտանիքի անդամ/ների, ձեզ հետ
        կապի մեջ գտնվող վստահելի այլ կոնտակտային անձի) հեռախոսահամար(ներ)ը
      </Title>
      <Info
        text={
          'ՑՈՒՑՈՒՄ. ԿԱՐԵՒՈՐ Է ՆՇԵԼ ՆՎԱԶԱԳՈՒՅՆԸ ՄԵԿ ՀԵՌԱԽՈՍԱՀԱՄԱՐ, ՍԱԿԱՅՆ ԵԹԵ ՉՈՒՆԻ՝ ՆՇԵԼ ՉՈՒՆԻ ՀԵՌԱԽՈՍԱՀԱՄԱՐ ՏԱՐԲԵՐԱԿԸ'
        }
      />
      <FormItem name="hasNotPhoneNumber" valuePropName="checked">
        <Checkbox
          onChange={(e) => onPhoneCheck(e.target.checked)}
          disabled={disabled}
        >
          <SecondaryText>Չունի հեռախոսահամար </SecondaryText>
        </Checkbox>
      </FormItem>
      {!hasNotPhoneNumber && (
        <Form.List name={['phoneNumberList']}>
          {(fields, { add, remove }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <>
                <ManageFields fields={fields} add={add} />
                {fields.map((field) => {
                  return (
                    <PhoneNumberForm
                      field={field}
                      add={add}
                      remove={remove}
                      key={field.key}
                      disabled={disabled}
                    />
                  )
                })}
              </>
              {!disabled && (
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  onClick={() => add()}
                  style={{ margin: '20px auto' }}
                >
                  <PlusCircleOutlined />
                  Ավելացնել հեռախոսահամար
                </Button>
              )}
            </div>
          )}
        </Form.List>
      )}
    </>
  )
}

export default PhoneNumberListForm
