import React, { useCallback, useState, useEffect } from 'react'
import { Form, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { FormSectionCard } from '../../card/form-section-card'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_INFORMATION_ACCESSIBILITY } from './constant'
import { SmallInput } from '../../input/small-input'

const InformationAvailability = ({ getData }) => {
  const { data: questions, isLoading } = useGetQuestions(
    URL_GET_INFORMATION_ACCESSIBILITY,
  )

  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})

  useEffect(() => {
    if (getData) {
      const householdInformationAccessibilityNeeds =
        getData?.householdInformationAccessibilityNeeds?.reduce(
          (acc, types) => {
            acc[types.id] = {
              ...types,
            }
            return acc
          },
          {},
        ) || {}

      setTimeout(() => {
        form.setFieldsValue({
          householdInformationAccessibilityNeeds:
            householdInformationAccessibilityNeeds,
        })
        setCheckedIds(householdInformationAccessibilityNeeds)
      }, 300)
    }
  }, [form, getData])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[item.id]) {
        return true
      }
      if (item.children && checkedIds[item.id] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }
  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: ['householdInformationAccessibilityNeeds', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [
              'householdInformationAccessibilityNeeds',
              itemName,
              'comment',
            ],
            value: '',
          },
        ])
      }
    },
    [form],
  )

  useEffect(() => {
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = item.id
          const isChecked = form.getFieldValue([
            'householdInformationAccessibilityNeeds',
            itemName,
            'isActive',
          ])
          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children) // Recursively handle children
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const initialCheckedIds = setInitialCheckedState(questions)
    setCheckedIds(initialCheckedIds)
  }, [form, questions])

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem
              name={['householdInformationAccessibilityNeeds', itemName]}
              style={{ marginLeft }}
              rules={[
                {
                  validator: () =>
                    validateCheckboxGroup(items)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                          ),
                        ),
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Checkbox
                checked={checkedIds[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(itemName, e.target.checked, item.id)
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
            {item.hasComment && checkedIds[itemName] && (
              <FormItem
                name={[
                  'householdInformationAccessibilityNeeds',
                  itemName,
                  'comment',
                ]}
                rules={[
                  { required: true },
                  {
                    pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                    message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                  },
                ]}
                style={{ width: '200px' }}
              >
                <SmallInput size="small" />
              </FormItem>
            )}
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title={
          'Ստացված տեղեկատվության մատչելիության ապահովման ի՞նչ կարիքներ կառանձնացնեք'
        }
      >
        {renderItems(questions)}
      </FormSectionCard>
    </Spin>
  )
}

export default InformationAvailability
