import React, { useEffect, useMemo } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import ProfessionalSupportNeeds from './professional-support-needs/index'
import FamilyMembersSituation from './family-members-situation/index'
import FamilyNeededToDoctor from './family-needed-to-doctor'
import ChildrenHadHealthIssues from './children-had-health-issues'
import PharmacyType from './pharmacy-type'

import useGetQuestions from '../../../api/application/use-get-questions'
import {
  URL_GET_HOUSE_HOLD_PERSONS,
  URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS,
} from './constants'
import { Form } from 'antd'

const SixthStep = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  const { data: dataFamily } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  const { data: dataFamilyChildrens } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  const transformedData = useMemo(() => {
    if (!getData) return {}

    return {
      isSatisfiedPolyclinic: getData?.isSatisfiedPolyclinic ? 1 : 2,
      visitDoctor: getData?.visitDoctor,
      nonRegisteredPolyclinicReason:
        getData?.householdNonChildrenVisitPolyclinicReasons?.reduce(
          (acc, needs) => {
            acc[`NonChildrenVisitPolyclinicReason${needs.id}`] = {
              ...needs,
            }
            return acc
          },
          {},
        ),
      ...getData?.householdNonChildrenVisitPolyclinicReasons?.reduce(
        (acc, needs) => {
          acc[`nonRegisteredPolyclinicReasonInput${needs.id}`] = needs?.comment
          return acc
        },
        {},
      ),
      professionalSupport: getData?.personHealthNeeds?.reduce((acc, needs) => {
        acc[`HealthNeed${needs.id}`] = { ...needs, personIds: needs?.personIds }
        return acc
      }, {}),
      ...getData?.personHealthNeeds?.reduce((acc, needs) => {
        acc[`selectionSupport${needs.id}`] = getData?.personHealthNeeds?.reduce(
          (acc1, needs1) => {
            acc1[`HealthNeed${needs1.id}`] = {
              ...needs1,
            }
            return acc1
          },
          {},
        )
        return acc
      }, {}),
      ...getData?.personHealthNeeds?.reduce((acc, needs) => {
        acc[`selectionSupportFamilyData${needs.id}`] = needs?.personIds
        acc[`selectSelectionSupportFamilyData${needs.id}`] = needs?.personIds
        acc[`professionalSupportInput${needs.id}`] = needs?.comment
        return acc
      }, {}),
    }
  }, [getData])
  // Update form values only when getData changes
  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        ...getData,
        reasonsForNotBeingChildren: getData?.reasonsForNotbeingChildren,
        reasonsForNotBeingAdult: getData?.reasonsForNotBeingAdult,
        ...transformedData,
      })
    } else {
      const fieldsToReset = [
        'professionalSupport',
        'familySituation',
        'visitDoctor',
        'hasChildrenHealthProblem',
        'changePharmacyType',
      ]

      for (let i = 1; i <= 100; i++) {
        fieldsToReset.push(`selectionSupport${i}`)
      }

      form.resetFields(fieldsToReset)
    }
  }, [getData, transformedData, form])

  return (
    <VerticalSpace>
      <ProfessionalSupportNeeds
        dataFamily={dataFamily}
        getData={getData}
        disabled={disabled}
      />
      <FamilyMembersSituation
        getData={getData}
        dataFamily={dataFamily}
        disabled={disabled}
      />
      <FamilyNeededToDoctor getData={getData} disabled={disabled} />
      {dataFamilyChildrens.length > 0 && (
        <ChildrenHadHealthIssues disabled={disabled} />
      )}
      <PharmacyType getData={getData} disabled={disabled} />
    </VerticalSpace>
  )
}

export default SixthStep
