import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Radio, Row, Spin } from 'antd'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { FormSectionCard } from '../../../card/form-section-card'
import { VerticalSpace } from '../../../space/vertical-space'
import { FormItem } from '../../../form/form-item'
import { BoldText } from '../../../typography/text'
import { COLORS } from '../../../../helpers/constants'
import { URL_GET_HEALTH_SITUATION } from '../constants'
import { Button } from '../../../button'
import { Text } from '../../../typography'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import FmSelect from './fm-select'

export const FamilyMembersSituation = ({ getData, dataFamily }) => {
  // eslint-disable-next-line no-unused-vars
  const [familySituation, setFamilySituation] = useState([])
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(null)
  const appliancesChecklist = Form.useWatch(['familySituation'])
  const { data: questions, isLoading } = useGetQuestions(
    URL_GET_HEALTH_SITUATION,
  )

  useEffect(() => {
    if (getData) {
      const initialStrategies = getData?.personHealthSituations?.reduce(
        (acc, curr) => {
          acc[`HealthSituation${curr.id}`] = {
            id: curr.id,
            answerId: curr.answerId,
          }
          return acc
        },
        {},
      )
      setFamilySituation(initialStrategies)

      const mapHealthSituations = () =>
        getData?.personHealthSituations?.reduce((acc, curr) => {
          const healthSituationData = curr.healthSituationPeople
            ?.filter(
              (person) => person.personId !== null && person.periodId !== null,
            )
            ?.map((person) => ({
              [`selectedFamilyMemberValue${curr.id}`]: person?.personId || null,
              [`familySituationNeedToSeeSpecialist${curr.id}`]:
                person?.periodId || null,
            }))

          if (healthSituationData?.length > 0) {
            acc[`requestMemberItemsHealthSituation${curr.id}`] =
              healthSituationData
          }
          return acc
        }, {})

      const flattenedHealthSituations = mapHealthSituations()

      form.setFieldsValue({
        familySituation: initialStrategies,
        ...flattenedHealthSituations,
      })
    }
  }, [getData, form])

  const handleRadioChange = (item, selectedValue) => {
    const currentData = form.getFieldValue('personHealthSituations') || []
    const existingSituation = currentData.find(
      (situation) => situation.id === item.id,
    )
    const updatedSituation = {
      id: item.id,
      answerId: selectedValue,
      healthSituationPeople: existingSituation?.healthSituationPeople || [],
    }
    const updatedData = currentData.filter(
      (situation) => situation.id !== item.id,
    )
    updatedData.push(updatedSituation)
    setFamilySituation((prev) => ({
      ...prev,
      [`HealthSituation${item.id}`]: updatedSituation,
    }))
    form.setFieldsValue({
      personHealthSituations: updatedData,
    })
  }

  const onChangeSetValueFamilySituationNeedToSeeSpecialist = useCallback(
    (value, id, indexRow) => {
      const index = form
        .getFieldValue('personHealthSituations')
        .findIndex((r) => r.id === id)

      const newData = [...form.getFieldValue('personHealthSituations')]

      if (index !== -1) {
        if (!newData[index].healthSituationPeople) {
          newData[index].healthSituationPeople = []
        }

        if (newData[index].healthSituationPeople[indexRow] !== undefined) {
          newData[index].healthSituationPeople[indexRow].periodId = value
        } else {
          newData[index].healthSituationPeople[indexRow] = {
            personId: null,
            periodId: value,
          }
        }

        form.setFieldsValue({ personHealthSituations: newData })
      }
    },
    [form],
  )

  const getAnswerId = (id) =>
    appliancesChecklist?.[`HealthSituation${id}`]?.answerId

  const handleRemove = (field, remove, index) => {
    remove(field.name)

    const updatedData = form.getFieldValue('personHealthSituations')

    const updatedHealthSituations = updatedData.map((situation) => {
      if (situation.id === field.name[0]) {
        situation.healthSituationPeople.splice(index, 1)
      }
      return situation
    })

    form.setFieldsValue({ personHealthSituations: updatedHealthSituations })
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem name="personHealthSituations" hidden initialValue={[]} />
      <FormSectionCard
        title={
          'Հիմա կթվեմ մի շարք իրավիճակներ։ Արդյո՞ք, Դուք կամ Ձեր ընտանիքի անդամները ներկայումս այդպիսի իրավիճակում եք։'
        }
        showRequired={false}
      >
        <VerticalSpace>
          <Row>
            <FormItem name={'familySituation'}>
              {questions.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <FormItem
                      name={['familySituation', item.label]}
                      rules={[{ required: true, message: '' }]}
                      label={
                        <BoldText color={COLORS.PRIMARY.BLUE}>
                          {item.index}. {item.name}
                        </BoldText>
                      }
                    >
                      <Radio.Group>
                        <FormItem
                          name={['familySituation', item.label, 'answerId']}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              handleRadioChange(item, e.target.value)
                              form.resetFields([
                                `requestMemberItems${item.label}`,
                              ])
                            }}
                          >
                            <VerticalSpace direction="vertical">
                              <Radio value={1}>Այո</Radio>
                              {!!(getAnswerId(item.id) === 1) && (
                                <Form.List
                                  name={`requestMemberItems${item.label}`}
                                  initialValue={[{}]}
                                >
                                  {(fields, { add, remove }) =>
                                    fields.map((field, index) => (
                                      // eslint-disable-next-line react/jsx-key
                                      <FormSectionCard
                                        title={
                                          fields.length > 1 && index !== 0 ? (
                                            <Col
                                              span={2}
                                              style={{
                                                alignSelf: 'center',
                                                marginBottom: '-10px',
                                              }}
                                            >
                                              <Button
                                                style={{ borderRadius: '0' }}
                                                onClick={() => {
                                                  handleRemove(
                                                    field,
                                                    remove,
                                                    index,
                                                  )
                                                }}
                                              >
                                                <MinusOutlined />
                                              </Button>
                                            </Col>
                                          ) : (
                                            <Col
                                              span={2}
                                              style={{
                                                alignSelf: 'center',
                                                marginBottom: '-10px',
                                              }}
                                            >
                                              <Button
                                                style={{ borderRadius: '0' }}
                                                onClick={() => add()}
                                              >
                                                <PlusOutlined
                                                  style={{
                                                    color: COLORS.PRIMARY.BLUE,
                                                    padding: 0,
                                                  }}
                                                />
                                              </Button>
                                            </Col>
                                          )
                                        }
                                        showRequired={false}
                                        style={{ marginBottom: '20px' }}
                                      >
                                        <Row gutter={16} key={index}>
                                          <Col
                                            span={24}
                                            style={{ alignSelf: 'center' }}
                                          >
                                            <Text
                                              style={{ fontSize: '20px' }}
                                              color={COLORS.PRIMARY.BLUE}
                                            >
                                              Ցանկից ընտրել հարցվող անդամի
                                              անունը
                                            </Text>
                                            <FmSelect
                                              nameItem={[
                                                field.name,
                                                `selectedFamilyMemberValue${item.id}`,
                                              ]}
                                              dataFamily={dataFamily}
                                              item={item}
                                              indexRow={index}
                                              style={{
                                                marginTop: '15px',
                                                marginLeft: '25px',
                                                width: '350px',
                                              }}
                                            />
                                          </Col>
                                          <Col
                                            span={24}
                                            style={{ alignSelf: 'center' }}
                                          >
                                            <FormItem
                                              name={[
                                                field.name,
                                                `familySituationNeedToSeeSpecialist${item.id}`,
                                              ]}
                                              style={{
                                                marginLeft: '25px',
                                                width: '-webkit-fill-available',
                                              }}
                                              label={
                                                <Text
                                                  color={
                                                    COLORS.PRIMARY.GRAY_DARK
                                                  }
                                                >
                                                  Որքան շուտ մասնագետին
                                                  հանդիպելու կարիք ունեք
                                                </Text>
                                              }
                                              rules={[{ required: true }]}
                                            >
                                              <Radio.Group
                                                style={{
                                                  marginLeft: '20px',
                                                  width: '100%',
                                                }}
                                                onChange={(e) => {
                                                  setValue(e.target.value)
                                                  onChangeSetValueFamilySituationNeedToSeeSpecialist(
                                                    e.target.value,
                                                    item.id,
                                                    index,
                                                  )
                                                }}
                                              >
                                                <VerticalSpace direction="vertical">
                                                  <Radio value={1}>
                                                    Մինչև 1 շաբաթ
                                                  </Radio>
                                                  <Radio value={2}>
                                                    2 շաբաթից մինչև 1 ամիս
                                                  </Radio>
                                                  <Radio value={3}>
                                                    1 ամսից ավել
                                                  </Radio>
                                                  <Radio value={4}>չգիտի</Radio>
                                                </VerticalSpace>
                                              </Radio.Group>
                                            </FormItem>
                                          </Col>
                                        </Row>
                                      </FormSectionCard>
                                    ))
                                  }
                                </Form.List>
                              )}
                              <Radio value={2}>Ոչ</Radio>
                              <Radio value={3}>Չգիտի</Radio>
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      </Radio.Group>
                    </FormItem>
                  </React.Fragment>
                )
              })}
            </FormItem>
          </Row>
        </VerticalSpace>
      </FormSectionCard>
    </Spin>
  )
}

export default FamilyMembersSituation
