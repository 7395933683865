import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, Radio } from 'antd'
import { FormItem } from '../../form-item'
import { Checkbox } from '../../../checkbox'
import { physycalSupportOptions } from '../../../fill-application/constatns'
import { SecondaryText } from '../../../typography'

const createName = (id) => `item${id}`

// eslint-disable-next-line no-unused-vars
export const MedicalSupport = ({ name, field, getData, disabled }) => {
  const form = Form.useFormInstance()
  const [medicalSupportIds, setMedicalSupportIds] = useState({})
  const [hasGuardian, setHasGuardian] = useState(null)
  const [isMember, setIsMember] = useState(null)
  const [guardianName, setGuardianName] = useState('')

  useEffect(() => {
    if (getData) {
      const initialCheckedState = getData?.familyData?.familyMembers.reduce(
        (acc, item) => {
          item?.personMedicalSupportTypes?.forEach((supportType) => {
            acc[`item${supportType.id}`] = {
              ...supportType,
              comment: supportType?.comment,
            }
          })
          return acc
        },
        {},
      )

      const initialGuardianValue = getData?.familyData?.familyMembers.find(
        (member) => member.hasGuardian !== undefined,
      )?.hasGuardian

      const initialGuardianFamilyMemberValue =
        getData?.familyData?.familyMembers.find(
          (member) => member.guardianFamilyMember !== undefined,
        )?.guardianFamilyMember

      const initialGuardianNameValue = getData?.familyData?.familyMembers.find(
        (member) => member.guardianName !== undefined,
      )?.guardianName

      setGuardianName(initialGuardianNameValue)
      setHasGuardian(initialGuardianValue)
      setIsMember(initialGuardianFamilyMemberValue)
      setMedicalSupportIds(initialCheckedState || [])
    }
  }, [getData, form, field])

  const validateCheckboxGroup = (items) => {
    const checkedItems = Object.values(medicalSupportIds).filter((item) => item)
    if (checkedItems.length < 1) {
      return false
    }

    for (const item of items) {
      if (medicalSupportIds[createName(item.id)]) {
        return true
      }
      if (
        item.children &&
        medicalSupportIds[createName(item.id)] !== undefined
      ) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setMedicalSupportIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
        comment: '',
      }))
      form.setFields([
        {
          name: [
            'familyData',
            'familyMembers',
            field.name,
            'personMedicalSupportTypes',
            itemName,
            'id',
          ],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [
              'familyData',
              'familyMembers',
              field.name,
              'personMedicalSupportTypes',
              itemName,
              'comment',
            ],
            value: '',
          },
        ])

        if (itemId === 1) {
          setHasGuardian(null)
          setGuardianName('')
          setIsMember(null)
          form.resetFields([
            ['familyData', 'familyMembers', field.name, 'hasGuardian'],
            ['familyData', 'familyMembers', field.name, 'guardianFamilyMember'],
          ])
        }
      }

      form.validateFields([
        'familyData',
        'familyMembers',
        field.name,
        'personMedicalSupportTypes',
      ])
    },
    [form, field],
  )

  const handleInputChange = useCallback(
    (itemName, itemId, value) => {
      setMedicalSupportIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: {
          id: itemId,
          comment: value,
        },
      }))

      const currentFormValues =
        form.getFieldValue([
          'familyData',
          'familyMembers',
          field.name,
          'personMedicalSupportTypes',
        ]) || []

      if (!Array.isArray(currentFormValues)) {
        return
      }

      const updatedFormValues = currentFormValues.map((item) => {
        if (item.id === itemId) {
          return { ...item, comment: value }
        }
        return item
      })

      form.setFieldsValue({
        familyData: {
          ...form.getFieldsValue(true).familyData,
          familyMembers: {
            ...form.getFieldsValue(true).familyData.familyMembers,
            [field.name]: {
              ...form.getFieldsValue(true).familyData.familyMembers[field.name],
              personMedicalSupportTypes: updatedFormValues,
            },
          },
        },
      })
    },
    [field.name, form],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'personMedicalSupportTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () => {
                  return validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('Անհրաժեշտ է նշել նվազագույնը մեկ արժեք'),
                      )
                },
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={medicalSupportIds[itemName]}
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.label}
            </Checkbox>
          </FormItem>
          {item.hasComment && medicalSupportIds[itemName] && (
            <FormItem
              name={[
                field.name,
                'personMedicalSupportTypes',
                itemName,
                'comment',
              ]}
              rules={[
                { required: true },
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              style={{ marginLeft }}
            >
              <Input
                // value={medicalSupportIds[itemName]?.comment}
                onChange={(e) =>
                  handleInputChange(itemName, item.id, e.target.value)
                }
                disabled={disabled}
              />
            </FormItem>
          )}
          {!!(medicalSupportIds?.item1 && item.id === 1) && (
            <>
              <FormItem
                rules={[{ required: true }]}
                label={
                  <SecondaryText>
                    Արդյոք նա ունի խնամող (գիշերակացով կամ առանց գիշերակացի)
                  </SecondaryText>
                }
                name={[field.name, 'hasGuardian']}
              >
                <Radio.Group
                  value={hasGuardian}
                  onChange={(e) => {
                    setHasGuardian(e.target.value)

                    if (e.target.value === false) {
                      form.resetFields([
                        [
                          'familyData',
                          'familyMembers',
                          field.name,
                          'guardianFamilyMember',
                        ],
                      ])
                      setHasGuardian(null)
                      setGuardianName('')
                      setIsMember(null)
                    }
                  }}
                  disabled={disabled}
                >
                  <Radio value={true}>Այո</Radio>
                  <Radio value={false}>Ոչ</Radio>
                </Radio.Group>
              </FormItem>
              {hasGuardian && (
                <FormItem
                  label={
                    <SecondaryText>
                      Արդյոք խնամողն ընտանիքի անդամ է
                    </SecondaryText>
                  }
                  style={{ marginLeft: '20px' }}
                  rules={[{ required: true }]}
                  name={[field.name, 'guardianFamilyMember']}
                >
                  <Radio.Group
                    value={isMember}
                    disabled={disabled}
                    onChange={(e) => {
                      setIsMember(e.target.value)
                      setGuardianName('')
                      form.resetFields([
                        [
                          'familyData',
                          'familyMembers',
                          field.name,
                          'guardianName',
                        ],
                      ])
                    }}
                  >
                    <Radio value={true}>Այո</Radio>
                    <Radio value={false}>Ոչ</Radio>
                  </Radio.Group>
                </FormItem>
              )}
              {!!(isMember && hasGuardian) && (
                <FormItem
                  style={{ marginLeft: '20px' }}
                  rules={[
                    { required: true },
                    {
                      pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                      message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                    },
                  ]}
                  name={[field.name, 'guardianName']}
                  label={'Նշել անունը'}
                >
                  <Input value={guardianName} disabled={disabled} />
                </FormItem>
              )}
            </>
          )}
        </React.Fragment>
      )
    })
  }
  return <>{renderItems(physycalSupportOptions)}</>
}
