import React, { useCallback, useEffect, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Checkbox, Col, Form, Radio, Row, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { URL_GET_LANGUAGE } from './constants'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Select } from '../../select'
import { Input } from '../../input'

const LanguagePart = ({ dataFamily, getData, disabled }) => {
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState({})
  const [value, setValue] = useState(null)

  const { data: dataLanguage, isLoading } = useGetQuestions(URL_GET_LANGUAGE)

  const radioLanguagePart = Form.useWatch('hasForeignLanguage', {
    preserve: true,
  })

  const checkboxLanguage = Form.useWatch('checkboxLanguage', {
    preserve: true,
  })

  useEffect(() => {
    if (getData) {
      const updatedCheckedIds = {}
      const checkboxLanguageList = getData?.householdLanguages?.reduce(
        (acc, curr) => {
          acc[`Language${curr.id}`] = {
            ...curr,
            personIds: curr.personIds
              ? curr.personIds.map((person) => person)
              : [],
          }
          updatedCheckedIds[`${curr.id}`] = curr.isActive
          return acc
        },
        {},
      )

      if (
        checkboxLanguageList &&
        Object.keys(checkboxLanguageList).length > 0
      ) {
        const personIdByLanguage = Object.keys(checkboxLanguageList).reduce(
          (acc, key) => {
            const languageData = checkboxLanguageList[key]
            if (languageData.personIds?.length > 0) {
              acc[key] = languageData.personIds
            }
            return acc
          },
          {},
        )

        form.setFieldsValue({
          checkboxLanguage: checkboxLanguageList,
          personId: personIdByLanguage,
        })

        setChecked(updatedCheckedIds)
      }
    }
  }, [form, getData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeLanguage = useCallback((checked, row) => {
    setChecked((prev) => ({
      ...prev,
      [row.id]: checked,
    }))

    if (checked) {
      form.setFieldsValue({
        householdLanguages: [
          ...form.getFieldValue('householdLanguages'),
          { id: row.id, comment: null, personIds: [] },
        ],
      })
    } else {
      form.setFieldsValue({
        householdLanguages: form
          .getFieldValue('householdLanguages')
          .filter((r) => r.id !== row.id),
      })
    }
  })

  const onInputChangeLanguageSelect = useCallback(
    (value, id) => {
      const languages = form.getFieldValue('householdLanguages') || []
      const index = languages.findIndex((r) => r.id === id)

      if (index !== -1) {
        const newData = [...languages]
        // newData[index].comment = value.comment
        newData[index].personIds = value
        form.setFieldsValue({ householdLanguages: newData })
      }
    },
    [form],
  )

  const onInputChangeLanguage = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdLanguages')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('householdLanguages')]
      newData[index].comment = value
      newData[index].personIds = []
      form.setFieldsValue({ householdLanguages: newData })
    },
    [form],
  )

  // const validateCheckboxGroup = (items) => {
  //   for (const item of items) {
  //     if (checked[item.id]) {
  //       return true
  //     }
  //     if (item.children) {
  //       if (validateCheckboxGroup(item.children)) {
  //         return true
  //       }
  //     }
  //   }
  //   return false
  // }

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Ընտանիքում կա՞ անձ, ով տիրապետու՞մ է որևէ օտար լեզվի (լավ կամ գերազանց)"
        showRequired={true}
      >
        <FormItem name="householdLanguages" hidden initialValue={[]} />

        <Row>
          <FormItem
            name={'hasForeignLanguage'}
            style={{ width: '100%' }}
            rules={[{ required: true }]}
          >
            <Radio.Group
              disabled={disabled}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
                form.resetFields(['checkboxLanguage'])
                form.setFieldsValue({ householdLanguages: [] })
              }}
              style={{ width: '100%' }}
            >
              <VerticalSpace>
                <Radio value={true}>Այո</Radio>
                {radioLanguagePart === true ? (
                  <FormItem
                    name={['checkboxLanguage']}
                    style={{ marginLeft: '25px' }}
                    // rules={[{ required: true }]}
                  >
                    {dataLanguage?.map((row) => {
                      return (
                        <Row key={row.id} style={{ alignItems: 'center' }}>
                          <Col span={8}>
                            <FormItem
                              name={['checkboxLanguage', row.label]}
                              key={row.id}
                              valuePropName="checked"
                              rules={[
                                {
                                  validator: () => {
                                    const currentCheckboxState =
                                      form.getFieldValue('checkboxLanguage') ||
                                      {}
                                    const hasAnyChecked = Object.values(
                                      currentCheckboxState,
                                    ).some((item) => item)

                                    return hasAnyChecked
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error(
                                            'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                                          ),
                                        )
                                  },
                                  validateTrigger: 'onSubmit',
                                },
                              ]}
                              // rules={[
                              //   {
                              //     validator: () =>
                              //       validateCheckboxGroup(dataLanguage)
                              //         ? Promise.resolve()
                              //         : Promise.reject(
                              //             new Error(
                              //               'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                              //             ),
                              //           ),
                              //     validateTrigger: 'onSubmit',
                              //   },
                              // ]}
                            >
                              <Checkbox
                                checked={checked[row.id] || false}
                                // value={row.id}
                                disabled={disabled}
                                onChange={(e) => {
                                  setChecked(e.target.checked)
                                  onChangeLanguage(e.target.checked, row)
                                  form.resetFields([['personId', row.label]])
                                  form.resetFields([
                                    `checkboxLanguageInput${row.id}`,
                                  ])
                                }}
                              >
                                {row.name}
                              </Checkbox>
                            </FormItem>
                          </Col>
                          {checkboxLanguage && checkboxLanguage[row.label] ? (
                            row.hasComment === true ? (
                              <Col span={12}>
                                <FormItem
                                  rules={[
                                    { required: true },
                                    {
                                      pattern:
                                        /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                                      message:
                                        'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                                    },
                                  ]}
                                  name={`checkboxLanguageInput${row.id}`}
                                >
                                  <Input
                                    defaultValue={
                                      checkboxLanguage[`Language${row.id}`]
                                        ?.comment
                                    }
                                    disabled={disabled}
                                    placeholder={'Նշել'}
                                    onChange={(e) =>
                                      onInputChangeLanguage(
                                        e.target.value,
                                        row.id,
                                      )
                                    }
                                  />
                                </FormItem>
                                <FormItem
                                  name={['personId', row.label]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    mode={'multiple'}
                                    placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                                    onChange={(e) => {
                                      onInputChangeLanguageSelect(e, row.id)
                                    }}
                                    disabled={disabled}
                                  >
                                    {dataFamily?.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.firstName + ' ' + item.lastName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            ) : (
                              <Col span={12}>
                                <FormItem
                                  name={['personId', row.label]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    mode={'multiple'}
                                    placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                                    onChange={(e) => {
                                      onInputChangeLanguageSelect(e, row.id)
                                    }}
                                    disabled={disabled}
                                  >
                                    {dataFamily?.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.firstName + ' ' + item.lastName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            )
                          ) : (
                            <FormItem hidden />
                          )}
                        </Row>
                      )
                    })}
                  </FormItem>
                ) : (
                  <FormItem hidden />
                )}
                <Radio value={false}>Ոչ</Radio>
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
        </Row>
      </FormSectionCard>
    </Spin>
  )
}

export default LanguagePart
