import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import FamilyMemberOccupancy from '../../form/application/family/family-member-occupancy-form'
import { Form } from 'antd'

const EighthStepForm = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const personWorksData = getData?.personWorks?.map((person) => ({
        ...person,
        wantsToWork: person?.nonWork,
        afterWorkTypes:
          person?.afterWorkTypes.reduce((acc, workType) => {
            acc[`item${workType.id}`] = {
              ...workType,
              workNeeds:
                workType?.workNeeds?.reduce((acc, workNeeds) => {
                  acc[`item${workNeeds.id}`] = {
                    ...workNeeds,
                  }
                  return acc
                }, {}) || {},
              workInvests:
                workType?.workInvests?.reduce((acc, workInvests) => {
                  acc[`item${workInvests.id}`] = {
                    ...workInvests,
                    checked: true,
                  }
                  return acc
                }, {}) || {},
            }
            return acc
          }, {}) || {},
        beforeWorkTypes:
          person?.beforeWorkTypes.reduce((acc, workType) => {
            acc[`item${workType.id}`] = {
              ...workType,
            }
            return acc
          }, {}) || {},
        nonWorkReasons:
          person?.nonWorkReasons?.reduce((acc, nonWork) => {
            acc[`item${nonWork.id}`] = {
              ...nonWork,
            }
            return acc
          }, {}) || {},
      }))

      form.setFieldsValue({
        personWorks: personWorksData,
      })
    } else {
      form.resetFields(['personWorks'])
    }
  }, [getData, form])

  return (
    <VerticalSpace>
      <FamilyMemberOccupancy disabled={disabled} />
    </VerticalSpace>
  )
}

export default EighthStepForm
