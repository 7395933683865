/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import FormItem from 'antd/es/form/FormItem'
import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions, {
  URL_GET_WORK_INVEST,
  URL_GET_WORK_NEEDS,
} from '../../../api/application/use-get-questions'
import { Form } from '../../form/form'
import { Checkbox, Spin } from 'antd'
import { Title } from '../../typography'
import { Input } from '../../input'
import { FormSectionFullCard } from '../../card/form-section-full-card'

const createName = (id) => `item${id}`

const WorkInvestment = ({ parentProp, disabled }) => {
  const { data: needQuestions, isLoading: isLoadingNeeds } =
    useGetQuestions(URL_GET_WORK_NEEDS)
  const { data: investQuestions, isLoading: isLoadingInvest } =
    useGetQuestions(URL_GET_WORK_INVEST)
  const form = Form.useFormInstance()
  const [needCheckedIds, setNeedCheckedIds] = useState({})
  const [investCheckIds, setInvestCheckIds] = useState({})

  useEffect(() => {
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = createName(item.id)
          const isChecked = form.getFieldValue([
            'personWorks',
            ...parentProp,
            'workNeeds',
            itemName,
            'isActive',
          ])

          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children)
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const setInitialCheckedStateInvests = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = createName(item.id)
          const isChecked = form.getFieldValue([
            'personWorks',
            ...parentProp,
            'workInvests',
            itemName,
            'isActive',
          ])

          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children)
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const initialCheckedIds = setInitialCheckedState(needQuestions)
    const initialCheckedInvestsIds =
      setInitialCheckedStateInvests(investQuestions)
    setNeedCheckedIds(initialCheckedIds)
    setInvestCheckIds(initialCheckedInvestsIds)
  }, [form, investQuestions, needQuestions, parentProp])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, parent, item) => {
      const updateCheckedIds = (prevCheckedIds, checked, itemName) => {
        const newCheckedIds = { ...prevCheckedIds }
        if (checked) {
          newCheckedIds[itemName] = true
        } else {
          delete newCheckedIds[itemName]
        }
        return newCheckedIds
      }

      if (parent === 'workNeeds') {
        setNeedCheckedIds((prevCheckedIds) => {
          const updatedCheckedIds = updateCheckedIds(
            prevCheckedIds,
            checked,
            itemName,
          )

          if (!checked && item.children) {
            item.children.forEach((child) => {
              const childName = createName(child.id)
              delete updatedCheckedIds[childName]
            })
          }

          return updatedCheckedIds
        })
      } else {
        setInvestCheckIds((prevCheckedIds) => {
          const updatedCheckedIds = updateCheckedIds(
            prevCheckedIds,
            checked,
            itemName,
          )

          if (!checked && item.children) {
            item.children.forEach((child) => {
              const childName = createName(child.id)
              delete updatedCheckedIds[childName]
            })
          }

          return updatedCheckedIds
        })
      }

      const updatedFieldValue = {
        ...form.getFieldValue(['personWorks', ...parentProp, parent]),
      }

      if (checked) {
        updatedFieldValue[itemName] = {
          id: itemId,
          comment: item.comment || '',
          isActive: true,
        }
      } else {
        delete updatedFieldValue[itemName]
      }

      form.setFields([
        {
          name: ['personWorks', ...parentProp, parent],
          value: updatedFieldValue,
        },
      ])
    },
    [form, parentProp],
  )

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && validateCheckboxGroup(item.children, state)) {
        return true
      }
    }
    return false
  }

  const renderItems = (items, parent, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentProp, parent, itemName, 'id']}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(
                    items,
                    parent === 'workNeeds' ? needCheckedIds : investCheckIds,
                  )
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={
                parent === 'workNeeds'
                  ? !!needCheckedIds[itemName]
                  : !!investCheckIds[itemName]
              }
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  parent,
                  item,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {(parent === 'workNeeds'
            ? needCheckedIds[itemName]
            : investCheckIds[itemName]) &&
            item.hasComment && (
              <>
                <FormItem
                  name={[...parentProp, parent, itemName, 'comment']}
                  style={{ marginLeft }}
                  rules={[
                    { required: true },
                    {
                      pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                      message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                    },
                  ]}
                >
                  <Input
                    value={
                      parent === 'workNeeds'
                        ? needCheckedIds?.[itemName]?.comment
                        : investCheckIds?.[itemName]?.comment
                    }
                    disabled={disabled}
                    placeholder="Նշել"
                  />
                </FormItem>
              </>
            )}
          {item.children &&
            item.children.length > 0 &&
            (parent === 'workNeeds'
              ? needCheckedIds[itemName]
              : investCheckIds[itemName]) &&
            renderItems(item.children, parent, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <Spin spinning={isLoadingNeeds || isLoadingInvest}>
      <FormSectionFullCard>
        <FormItem
          name={'workNeeds'}
          label={
            <Title>Զբաղվածության ապահովման հարցում ի՞նչ կարիքներ ունեք</Title>
          }
        >
          {renderItems(needQuestions, 'workNeeds')}
        </FormItem>

        <FormItem
          name={'workInvests'}
          label={
            <Title>
              Ի՞նչ ռեսուրսներ կարող եք ներդնել Ձեր զբաղվածության կազմակերպման
              հարցում
            </Title>
          }
        >
          {renderItems(investQuestions, 'workInvests')}
        </FormItem>
      </FormSectionFullCard>
    </Spin>
  )
}

export default WorkInvestment
