import dayjs from 'dayjs'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { COLORS, PATHS } from '../../../helpers/constants'
import { useNavigate } from 'react-router-dom'

export const useColumns = (currentPage, pageSize) => {
  const navigate = useNavigate()
  const getIndex = (record, index) =>
    (currentPage.pageNumber - 1) * pageSize + index + 1
  return [
    {
      title: 'ՀՀ',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      flex: 1,
      render: (_, record, index) => getIndex(record, index),
    },
    {
      title: 'Օր և ժամ',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      flex: 1,
      render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Դիմումի համար',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Անուն',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Ազգանուն',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Հայրանուն',
      dataIndex: 'patronymicName',
      key: 'patronymicName',
      align: 'center',
      flex: 1,
    },
    {
      title: 'ՀԾՀ',
      dataIndex: 'ssn',
      key: 'ssn',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Մարզ',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Համայնք',
      dataIndex: 'community',
      key: 'community',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Բնակավայր',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'householdStateId',
      key: 'householdStateId',
      align: 'center',
      render: (status) => {
        switch (status) {
          case 4:
            return 'Ամբողջական'
          case 2:
            return 'Թերի'
          case 3:
            return 'Դադարեցված'
          case 1:
            return 'Ընթացիկ'
        }
      },
    },
    {
      title: 'Նշումներ',
      dataIndex: 'customNote',
      key: 'customNote',
      align: 'center',
      flex: 1,
      render: (text) =>
        text?.length >= 10 ? `${text.substring(0, 10)}...` : text,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      render: (item) => {
        if (item.householdStateId === 1 || item.householdStateId === 2) {
          return (
            <EditOutlined
              onClick={() => {
                localStorage.setItem('jumpValue', item.lastPage)
                localStorage.setItem('notificationShown', false)

                navigate(PATHS.FILL, {
                  state: {
                    householdId: item.id,
                    status: item.householdStateId,
                    lastPage: item.lastPage,
                  },
                })
              }}
              title="Խմբագրել"
              style={{ color: COLORS.PRIMARY.BLUE, cursor: 'pointer' }}
            />
          )
        } else if (item.householdStateId === 4) {
          return (
            <EyeOutlined
              onClick={() => {
                localStorage.setItem('jumpValue', 'view')
                localStorage.setItem('notificationShown', false)

                navigate(PATHS.FILL, {
                  state: {
                    householdId: item.id,
                    status: item.householdStateId,
                    lastPage: item.lastPage,
                    disabled: true,
                  },
                })
              }}
              title="Դիտել"
              style={{ color: COLORS.PRIMARY.BLUE, cursor: 'pointer' }}
            />
          )
        }
        return <></>
      },
    },
  ]
}
