import { VerticalSpace } from '../../space/vertical-space'
import { BeforeIncomeTypes } from './before-income-types'
import { AfterIncomeTypes } from './after-income-types'
import { AverageMonthlyIncome } from './average-monthly-income'
import { DailyHouseholdNeeds } from './daily-household-needs'
// import { DoesTheFamilyHave } from './does-the-family-have'
import { IncomeAfterDisplacement } from './income-after-displacement'
import { useEffect } from 'react'
import { Form } from 'antd'

export const NinthStep = ({ getData, disabled = false }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        beforeMonthlyIncomePeriodId: getData?.beforeMonthlyIncomePeriodId,
        afterMonthlyIncomePeriodId: getData?.afterMonthlyIncomePeriodId,
      })
    } else {
      form.resetFields([
        'householdBeforeIncomeTypes',
        'householdAfterIncomeTypes',
        'beforeMonthlyIncomePeriodId',
        'afterMonthlyIncomePeriodId',
        'householdHaveToActions',
      ])
    }
  }, [getData, form])

  return (
    <VerticalSpace>
      <BeforeIncomeTypes getData={getData} disabled={disabled} />

      <AfterIncomeTypes getData={getData} disabled={disabled} />

      <AverageMonthlyIncome disabled={disabled} />

      <IncomeAfterDisplacement disabled={disabled} />

      {/*<DoesTheFamilyHave />*/}

      <DailyHouseholdNeeds getData={getData} disabled={disabled} />
    </VerticalSpace>
  )
}
