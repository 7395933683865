import React from 'react'
import { Form, Radio } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { Input } from '../../input'

const NestedRadio = ({
  row,
  level,
  selectedId,
  selectedNestedId,
  nestedFormItemName,
  disabled,
}) => {
  const form = Form.useFormInstance()
  const hasChildren = !!row.children.length

  return (
    <React.Fragment>
      <Radio value={row.id}>{row.name}</Radio>
      {selectedId === row.id && hasChildren && (
        <FormItem
          name={nestedFormItemName}
          rules={[{ required: true }]}
          style={{ paddingLeft: 30 * level, margin: '12px 0 0' }}
        >
          <Radio.Group
            onChange={() => {
              form.resetFields(['accommodationRiskTypeComment'])
            }}
            disabled={disabled}
          >
            <VerticalSpace>
              {row.children.map((nestedRow) => (
                <NestedRadio
                  key={nestedRow.id}
                  row={nestedRow}
                  level={level + 1}
                />
              ))}
              {((selectedId === 8 && selectedNestedId === 12) ||
                (selectedId === 1 && selectedNestedId === 7)) && (
                <FormItem
                  name="accommodationRiskTypeComment"
                  rules={[
                    { required: true },
                    {
                      pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                      message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                    },
                  ]}
                >
                  <Input placeholder="Նշել" />
                </FormItem>
              )}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
      )}
    </React.Fragment>
  )
}

export default NestedRadio
