import React, { useEffect } from 'react'
import { manifestationBy, whatSupportDoesItNeed } from '../../constatns'
import { FormItem } from '../../../form/form-item'
import { Checkbox, Form, Input, Radio } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { SecondaryText, Title } from '../../../typography'
import { Select } from '../../../select'
import useGetQuestions, {
  URL_GET_FAMILY_MEMBERS,
} from '../../../../api/application/use-get-questions'
import { createOptionsFromArray } from '../../../../helpers/utils'

const ManifestationForm = ({ getData, parentFormItem, disabled }) => {
  const selections = Form.useWatch(
    [
      ...parentFormItem,
      'householdViolencePersonTypes',
      'householdViolenceSupports',
      'checkIds',
    ],
    {
      preserve: true,
    },
  )

  const form = Form.useFormInstance()
  const applicationId = form.getFieldValue('appId')
  const personId = Form.useWatch([
    ...parentFormItem,
    'householdViolencePersonTypes',
    'id',
  ])

  const { data: members } = useGetQuestions(
    `${URL_GET_FAMILY_MEMBERS}?householdId=${+applicationId}`,
  )

  const formattedMembers = members?.map((member) => {
    return {
      label: `${member.firstName} ${member.lastName} `,
      id: member.id,
    }
  })

  useEffect(() => {
    if (getData === null) {
      form.resetFields([
        // [...parentFormItem, 'householdViolencePersonTypes'],
        // [...parentFormItem, 'householdViolencePersonTypes', 'personId'],
        // [...parentFormItem, 'householdViolencePersonTypes', 'comment'],
        // [
        //   ...parentFormItem,
        //   'householdViolencePersonTypes',
        //   'householdViolenceSupports',
        //   'checkIds',
        // ],
        // [
        //   ...parentFormItem,
        //   'householdViolencePersonTypes',
        //   'householdViolenceSupports',
        //   'comment',
        // ],
      ])
    }
  }, [form, getData, parentFormItem])

  return (
    <Content style={{ marginLeft: '60px' }}>
      <FormItem name={[...parentFormItem, 'householdViolencePersonTypes']}>
        <FormItem
          label={<Title>Ո՞ւմ կողմից է դրսևորվում</Title>}
          rules={[{ required: true }]}
          name={[...parentFormItem, 'householdViolencePersonTypes', 'id']}
        >
          <Radio.Group
            disabled={disabled}
            onChange={() => {
              form.resetFields([
                [...parentFormItem, 'householdViolencePersonTypes', 'personId'],
                [...parentFormItem, 'householdViolencePersonTypes', 'comment'],
                [
                  ...parentFormItem,
                  'householdViolencePersonTypes',
                  'householdViolenceSupports',
                  'checkIds',
                ],
                [
                  ...parentFormItem,
                  'householdViolencePersonTypes',
                  'householdViolenceSupports',
                  'comment',
                ],
              ])
            }}
            options={manifestationBy.map((i) => {
              return { label: i.name, value: i.id }
            })}
          />
        </FormItem>
        {personId === 6 && (
          <FormItem
            name={[
              ...parentFormItem,
              'householdViolencePersonTypes',
              'comment',
            ]}
            rules={[
              { required: true },
              {
                pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
              },
            ]}
          >
            <Input disabled={disabled} defaultValue={''} placeholder="Նշել" />
          </FormItem>
        )}
        {personId === 1 && (
          <FormItem
            name={[
              ...parentFormItem,
              'householdViolencePersonTypes',
              'personId',
            ]}
            rules={[{ required: true }]}
            label={<SecondaryText>Ընտրել ընտանիքի անդամին</SecondaryText>}
          >
            <Select
              disabled={disabled}
              options={createOptionsFromArray(formattedMembers)}
            />
          </FormItem>
        )}
        <FormItem
          name={[
            ...parentFormItem,
            'householdViolencePersonTypes',
            'householdViolenceSupports',
          ]}
        >
          <FormItem
            label={<SecondaryText>Ի՞նչ աջակցության կարիք ունի</SecondaryText>}
            name={[
              ...parentFormItem,
              'householdViolencePersonTypes',
              'householdViolenceSupports',
              'checkIds',
            ]}
            rules={[{ required: true }]}
          >
            <Checkbox.Group
              disabled={disabled}
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
              options={whatSupportDoesItNeed.map((i) => {
                return { label: i.name, value: i.id }
              })}
              onChange={(checkedValues) => {
                if (!checkedValues.includes(9)) {
                  // Reset the comment field if checkbox 9 is unchecked
                  form.resetFields([
                    [
                      ...parentFormItem,
                      'householdViolencePersonTypes',
                      'householdViolenceSupports',
                      'comment',
                    ],
                  ])
                }
              }}
            />
          </FormItem>
          {selections?.includes(9) && (
            <FormItem
              rules={[
                { required: true },
                {
                  pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                  message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                },
              ]}
              name={[
                ...parentFormItem,
                'householdViolencePersonTypes',
                'householdViolenceSupports',
                'comment',
              ]}
            >
              <Input disabled={disabled} />
            </FormItem>
          )}
          <FormItem hidden />
        </FormItem>
      </FormItem>
    </Content>
  )
}

export default ManifestationForm
