import React, { useCallback, useState, useEffect } from 'react'
import { Form } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { SmallInput } from '../../input/small-input'
import { FormSectionCard } from '../../card/form-section-card'
import { anyFamilyMember } from './constants'

export const AnyFamilyMember = ({ backendData, disabled }) => {
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdAnyFamilyMember', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: ['householdAnyFamilyMember', itemName, 'comment'],
            value: '',
          },
        ])
      }

      if (!checked) {
        const uncheckChildren = (parentId) => {
          const parentItem = anyFamilyMember.find(
            (item) => item.id === parentId,
          )
          if (parentItem && parentItem.children) {
            parentItem.children.forEach((child) => {
              const childName = child.id
              setCheckedIds((prevCheckedIds) => ({
                ...prevCheckedIds,
                [childName]: false,
              }))
              form.setFields([
                {
                  name: ['householdAnyFamilyMember', childName, 'id'],
                  value: false,
                },
                {
                  name: ['householdAnyFamilyMember', childName, 'comment'],
                  value: '',
                },
              ])
              if (child.children && child.children.length > 0) {
                uncheckChildren(childName)
              }
            })
          }
        }
        uncheckChildren(itemName)
      }
    },
    [form],
  )

  useEffect(() => {
    if (backendData) {
      const initialFormValues = {}
      backendData.forEach((item) => {
        const itemName = item.id
        initialFormValues[`householdAnyFamilyMember.${itemName}`] = {
          id: item.isActive ? item.id : false,
          comment: item.comment || '',
        }
        setCheckedIds((prevCheckedIds) => ({
          ...prevCheckedIds,
          [itemName]: item.isActive,
        }))
      })
      form.setFieldsValue(initialFormValues)
    }
  }, [backendData, form])

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem
              name={['householdAnyFamilyMember', itemName]}
              style={{ marginLeft }}
              rules={[
                {
                  validator: () =>
                    Object.values(checkedIds).some(Boolean)
                      ? Promise.resolve()
                      : Promise.reject(new Error('Պարտադիր դաշտ')),
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Checkbox
                checked={checkedIds[itemName]}
                disabled={disabled}
                onChange={(e) =>
                  handleCheckboxChange(itemName, e.target.checked, item.id)
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
            {item.hasComment && checkedIds[itemName] && (
              <FormItem
                name={['householdAnyFamilyMember', itemName, 'comment']}
                rules={[
                  { required: true },
                  {
                    pattern: /^[\u0531-\u05870-9\s.«»․։;/(),-]*$/,
                    message: 'Խնդրում ենք օգտագործել միայն հայերեն տառեր',
                  },
                ]}
              >
                <SmallInput disabled={disabled} size="small" />
              </FormItem>
            )}
          </div>
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard
      title="Ընտանիքի անդամներից որևէ մեկի կամ մի քանիսի համար ճշմարիտ են նշված դատողությունները"
      showRequired={true}
    >
      {renderItems(anyFamilyMember)}
    </FormSectionCard>
  )
}

export default AnyFamilyMember
