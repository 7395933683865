import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { SecondaryText } from '../../typography'
import { Radio } from 'antd'
import { Form } from '../../form/form'
import AddNonResideMemberForm from '../../form/application/family/add-non-reside-member'
import { AddFamilyMember } from '../../form/application/family/add-family-member'
import LostMemberForm from './has-lost-member-form'

import Notes from '../../form/application/notes'
import dayjs from 'dayjs'
const SecondStepForm = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const familyMembers = getData?.familyData?.familyMembers || []
      const displacedPersons = getData?.familyData?.displacedPersons || []
      const nonResidePersons = getData?.familyData?.nonResidePersons || []
      const processMembers = (members) => {
        const updatedMembers = members.map((item) => {
          return {
            ...item,
            birthdate: item.birthdate ? dayjs(item.birthdate) : null,
            personSocialStatuses: item?.personSocialStatuses?.reduce(
              (acc, curr) => {
                acc[`item${curr.id}`] = {
                  ...curr,
                }

                if (curr.personSocialStatusParentId) {
                  acc[`item${curr.personSocialStatusParentId}`] = {
                    ...curr,
                    radio: curr.id,
                  }
                }

                return acc
              },
              {},
            ),
            personMedicalSupportTypes: item?.personMedicalSupportTypes?.reduce(
              (acc, curr) => {
                acc[`item${curr.id}`] = {
                  ...curr,
                }
                return acc
              },
              {},
            ),
            medicalFrequencyTypeComment: item?.medicalFrequencyTypeComment,
          }
        })
        return updatedMembers
      }

      const familyMembersList = processMembers(familyMembers)
      const displacedPersonsList = processMembers(displacedPersons)
      const nonResidePersonsList = processMembers(nonResidePersons)

      form.setFieldsValue({
        hasNonResidePerson: getData?.hasNonResidePerson,
        page2notes: getData?.note,
        familyData: {
          familyMembers: familyMembersList,
          displacedPersons: displacedPersonsList,
          nonResidePersons: nonResidePersonsList,
          notResidePersonsStayInSameAccommodation:
            getData?.notResidePersonsStayInSameAccommodation,
        },
      })
    } else {
      form.resetFields([
        'hasNonResidePerson',
        'page2notes',
        'hasDiedPerson',
        ['familyData', 'familyMembers'],
        ['familyData', 'displacedPersons'],
        ['familyData', 'nonResidePersons'],
        ['familyData', 'notResidePersonsStayInSameAccommodation'],
      ])
    }
  }, [form, getData])

  const hasDisplacedPerson = Form.useWatch(
    ['familyData', 'notResidePersonsStayInSameAccommodation'],
    {
      preserve: true,
    },
  )
  const hasNonResidePerson = Form.useWatch(['hasNonResidePerson'], {
    preserve: true,
  })

  return (
    <>
      <VerticalSpace>
        <AddFamilyMember
          isFamilyMember={true}
          getData={getData}
          disabled={disabled}
        />
        <FormItem
          label={
            <SecondaryText>
              Կա՞ Ձեր ընտանիքի հետ ապրող ԼՂ-ից բռնի տեղահանված անձ, ով Ձեր
              ընտանիքի անդամ չէ
            </SecondaryText>
          }
          name={['familyData', 'notResidePersonsStayInSameAccommodation']}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
        {hasDisplacedPerson && (
          <AddFamilyMember
            isFamilyMember={false}
            getData={getData}
            disabled={disabled}
          />
        )}
        <FormItem
          label={
            <SecondaryText>
              Արդյո՞ք նախքան բռնի տեղահանումը, Ձեզ հետ միասին ապրել է այլ անձ,
              ով ներկայումս Ձեզ հետ այս նույն վայրում չի բնակվում
            </SecondaryText>
          }
          name={['hasNonResidePerson']}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
        {hasNonResidePerson && (
          <AddNonResideMemberForm getData={getData} disabled={disabled} />
        )}
        <LostMemberForm getData={getData} />
        <Notes formItemName={'page2notes'} />
      </VerticalSpace>
    </>
  )
}

export default SecondStepForm
